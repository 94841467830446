import { createContext } from 'react'

import { useProvideFirebase } from 'config/firebase'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const auth = useProvideFirebase()

  return (
    <AuthContext.Provider value={auth}>
      { !auth.loading && children}
    </AuthContext.Provider>
  )
}

export default AuthContext