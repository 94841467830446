import React from 'react'

import {
  Box,
  Grid,
  Typography
} from '@mui/material'

const Empty = (props) => {

  const { message, image } = props

  // Parece que no tienes eventos creados 

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} align="middle">
        <Box sx={{ p: 2, pb: 0, width: '100%' }}>
          <img
            src={image ? image : '/images/inbox.png'}
            alt="Empty"
            style={{ filter: 'grayscale(0.4)', opacity: '0.9', verticalAlign: 'middle', width: '30%' }}
          />
        </Box>
        <Typography color="#373f47aa" variant="body2" sx={{ p: 2 }}>
          { message } 
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Empty