import React from 'react'

import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material'

const About = (props) => {
  const { id } = props

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ my: 4, mb: 8, py: 0 }} alignItems='center'>
          <Grid item xs={12}>
            <Typography id={id} align='center' variant="h4" gutterBottom>
              <b>¿QUIÉNES SOMOS?</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mb: 4, mx: 6}} variant="h5" gutterBottom>
              Somos la institución afiliada a los organismos internacionales rectores del deporte, encargada de fomentar y regular la práctica del Fútbol Tenis y deportes a fines en México, creando un impacto positivo para afiliados y seguidores.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Box sx={{ px: 4, width: '100%' }}>
              <img
                src='fmfta/quienes-somos.jpg'
                alt='Quiénes somos'
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <b>Misión:</b> Cambiar vidas a través de nuestro deporte.
            </Typography>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <b>Visión:</b> Ser el deporte más inclusivo de nuestro país en pro de la salud, recreación, formación y desarrollo de la sociedad mexicana.
            </Typography>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <b>Propósito:</b> Usar el Fútbol Tenis como plataforma de desarrollo integral y accesible para todos.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default About