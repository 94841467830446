import React, { useState } from 'react'

import {
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'

import mxLocale from 'date-fns/locale/es'
import DateRangePicker from '@mui/lab/DateRangePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { MX } from 'country-region-data/dist/data.js'

const Filters = (props) => {
  const { onRangeChange, onRegionChange } = props

  const [range, setRange] = useState([null, null])
  const hangleRangeChange = (value) => {
    setRange(value)
    onRangeChange(value)
  }

  const [region, setRegion] = useState('Any')
  const handleRegionChange = (event) => {
    const value = event.target.value
    setRegion(value)
    onRegionChange(value)
  }

  return (
    <Card sx={{ p: 2 }}>
      <CardContent 
        sx={{ 
          '&:last-child': { paddingBottom: 0 },
          p: 0,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Filtros</b>
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <TextField
              fullWidth
              // label="Buscar"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder="Buscar evento"
              variant="outlined"
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              Lugar
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel shrink id="select-outlined-city">Estado</InputLabel>
              <Select
                labelId="select-outlined-city"
                id="select-outlined-city"
                onChange={handleRegionChange}
                value={region}
                label="Estado"
                notched
              >
                <MenuItem value='Any'>
                  <em>Cualquiera</em>
                </MenuItem>
                { 
                  MX[2].map(city =>
                    <MenuItem key={city[0]} value={city[1]}>
                      {city[0]}
                    </MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              Fechas
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={mxLocale}>
              <DateRangePicker
                startText="Desde"
                endText="Hasta"
                value={range}
                onChange={hangleRangeChange}
                renderInput={(startProps, endProps) => (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField {...startProps} InputLabelProps={{ shrink: true }} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField {...endProps} InputLabelProps={{ shrink: true }} fullWidth />
                    </Grid>
                  </Grid>
                )}
              />
            </LocalizationProvider>
          </Grid>
          {/* <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel control={<Checkbox />} label="Eventos preferentes" />
            </FormGroup>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Filters