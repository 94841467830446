import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  Fab,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useScrollTrigger,
  Zoom,
} from '@mui/material'

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MenuIcon from '@mui/icons-material/Menu'

import About from './sections/about'
import Footer from './sections/footer'
import Head from './sections/head'
import OurKits from './sections/kits'
import WhatIs from './sections/what'
import WhatWeDo from './sections/wedo'
import Contact from './sections/contact'

import { HashLink } from 'react-router-hash-link'

import { UserAgent } from '@quentin-sommer/react-useragent'

const ScrollTop = (props) => {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    )

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const ElevationScroll = (props) => {
  const { children, window } = props

  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
    sx: trigger ? { boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 5%), 0px 1px 1px 0px rgb(0 0 0 / 3%), 0px 2px 1px 0px rgb(0 0 0 / 3%)' } : {},
  })
}

const Landing = (props) => {
  const navigate = useNavigate()
  const { analyticsFor } = useAuth()

  const pages = [
    {
      name: '¿Quiénes somos?',
      id: 'quienes-somos',
    },
    {
      name: '¿Qué hacemos?',
      id: 'que-hacemos',
    },
    {
      name: '¿Qué es el Fútbol Tenis?',
      id: 'que-es',
    },
    {
      name: 'Nuestros kits',
      id: 'nuestros-kits',
    },
    {
      name: 'Contacto',
      id: 'contacto',
    },
  ]

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -100
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' })
  }

  const drawerWidth = 240

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <>
      <Toolbar>
        <Grid container>
          <Grid item xs align="middle">
            <Box sx={{ p: 0, width: '100%' }}>
              <img
                src="/images/fmft-logo-toolbar.png"
                alt="FMFTA Logo"
                style={{ opacity: 0.7, verticalAlign: 'middle', width: '70%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
      <List sx={{ p: 2 }}>
        {pages.map((page) => (
          <ListItem button 
            component={HashLink}
            scroll={el => scrollWithOffset(el)}
            smooth
            to={`/#${page.id}`}
            onClick={handleDrawerToggle}
          >
            <ListItemText primary={page.name} />
          </ListItem>
        ))}
      </List>
    </>
  )

  const container = window !== undefined ? () => window.document.body : undefined
  
  const onSignin = async (parser) => {
    await analyticsFor('landingSignInClicked', parser.getResult())
    navigate('/signin')
  }

  return (
    <React.Fragment>
      <ElevationScroll {...props}>
        <AppBar color="inherit">
          <Container sx={{ m: 0, maxWidth: '100% !important', p: '0px !important' }}>
            <Toolbar sx={{ height: '74px' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              {/* <Typography sx={{ display: { xs: 'none', md: 'none' } }} variant="h6">
                <b>FMFTA</b>
              </Typography> */}
              <Box
                component="img"
                sx={{ height: { xs: 20, md: 28 } }}
                alt="FMFT Logo"
                src='images/fmft-logo-toolbar.png'
              />
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="right"
                >
                  {pages.map((page) => (
                    <Button
                      key={page.id}
                      component={HashLink}
                      scroll={el => scrollWithOffset(el)}
                      smooth
                      size="small"
                      to={`/#${page.id}`}
                      sx={{ mx: 1, my: 2, color: '#3c4858', display: 'block', fontSize: { md: '11px', lg: 'small' }, fontWeight: 'bold' }}
                    >
                      {page.name}
                    </Button>
                  ))}
                </Grid>
              </Box>
              <Box sx={{ flexGrow: { xs: 1, md: 0 } }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="right"
                >
                  
                  <UserAgent returnFullParser>
                    {
                      parser => (
                        <Button
                        onClick={() => onSignin(parser)}
                        size="small"
                        variant="contained"
                        disableElevation
                        color="secondary"
                        sx={{ ml: 4, my: 2, display: 'block' }}
                      >
                        Iniciar sesión
                      </Button> 
                      )
                    }
                  </UserAgent>
                </Grid>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Drawer
        // anchor="left"
        container={container}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: 'block', md: 'none' },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            boxSizing: 'border-box',
            width: drawerWidth
          },
          width: drawerWidth,
        }}
        variant="temporary"
      >
        { drawer }
      </Drawer>
      <Toolbar sx={{ height: '74px' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ backgroundColor: 'white' }}>
          <Head id="back-to-top-anchor" />
        </Grid>
        <Grid item xs={12}>
          <About id={pages[0].id} />
        </Grid>
        <Grid item xs={12} sx={{ backgroundColor: 'white' }}>
          <WhatWeDo id={pages[1].id} />
        </Grid>
        <Grid item xs={12}>
          <WhatIs id={pages[2].id} />
        </Grid>
        <Grid item xs={12} sx={{ backgroundColor: 'white' }}>
          <OurKits id={pages[3].id} />
        </Grid>
        <Grid item xs={12}>
          <Contact id={pages[4].id} />
        </Grid>
        <Grid item xs={12} sx={{ backgroundColor: '#2b303a' }}>
          <Footer />
        </Grid>
      </Grid>
      <ScrollTop {...props}>
        <Fab color="secondary" size="small">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  )
}

export default Landing