import React, { useEffect, useState } from 'react'

import useAuth from 'hooks/auth'

import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'

import NavigationIcon from '@mui/icons-material/Navigation'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'

import Information from './information'
import Winner from './winner'
import Empty from 'components/empty'

import CreateEvent from 'pages/shared/events/create'

import { loadStripe } from '@stripe/stripe-js'

const formatDate = (date) => {
  var options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Date(date).toLocaleDateString('es-MX', options)
}

const formatHour = (date) => {
  var options = { hour: 'numeric', minute: 'numeric' }
  return new Date(date).toLocaleTimeString('es-MX', options)
}

const Summary = (props) => {
  const { user, value } = props

  const {
    addWinners,
    amIRegistered,
    cancelEvent,
    eventWinners,
    getParticipantsById,
    getWinnersById,
    participants,
    registerToEvent,
    updateEvent
  } = useAuth()

  // const [participants, setParticipants] = useState(null)
  const [registered, setRegistered] = useState(null)
  const [canRegister, setCanRegister] = useState(false)

  const [firstOneWinner, setFirstOneWinner] = useState(null)
  const [firstTwoWinner, setFirstTwoWinner] = useState(null)
  const [secondOneWinner, setSecondOneWinner] = useState(null)
  const [secondTwoWinner, setSecondTwoWinner] = useState(null)
  const [thirdOneWinner, setThirdOneWinner] = useState(null)
  const [thirdTwoWinner, setThirdTwoWinner] = useState(null)

  const [openCancel, setOpenCancel] = useState(false)
  const [isTryingToPay, setIsTryingToPay] = useState(false)

  const canBeSaved = firstOneWinner && firstTwoWinner && secondOneWinner && secondTwoWinner && thirdOneWinner && thirdTwoWinner

  const saveWinners = () => {
    const winners = [
      firstOneWinner,
      firstTwoWinner,
      secondOneWinner,
      secondTwoWinner,
      thirdOneWinner,
      thirdTwoWinner
    ]
    addWinners(value, winners)
  }

  // const message = `¡Hola! Mi nombre es *${user.name}* y mi correo es *${user.email}*, me gustaría inscribirme al evento *${value.title}*, que se llevará a cabo el *${formatDate(value.date.seconds * 1000)}* a las *${formatHour(value.time.seconds * 1000)}* Hrs.`

  useEffect(() => {
    getParticipantsById(value.id)
    getWinnersById(value.id)

    setTabValue(0)
    setRegistered(false)

    const isRegistered = async () => {
      const registered = await amIRegistered(value.id)
      setRegistered(registered)
    }

    isRegistered()

    const date = new Date(value.date.seconds * 1000).setHours(0, 0, 0, 0)
    const today = new Date().setHours(0, 0, 0, 0)
    
    setCanRegister(date > today)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const formattedParticipants = participants ? Object.values(participants).map((participant) => (
    {
      'email': participant.email,
      'id': participant.uid,
      'label': `${participant.name} <${participant.email}>`,
      'name': participant.name,
    }
  )) : {}

  const checkout = async () => {
    setIsTryingToPay(true)
    const sessionId = await registerToEvent(value)
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY)
    stripe.redirectToCheckout({ sessionId: sessionId })
  }

  const onCancel = () => {
    cancelEvent(value.id, value.title)
  }

  const [openEdit, setOpenEdit] = useState(false)
  const [edit, setEdit] = useState(false)

  const handleOpenEdit = () => {
    setOpenEdit(true)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
  }

  const onEdit = () => {
    setEdit(true)
    handleCloseEdit()
  }

  const onEditEvent = (event) => {
    setEdit(false)
    updateEvent(value.id, event)
  }

  return (
    <Card sx={{ p: 0 }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isTryingToPay}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <CardMedia
        component="img"
        height="196"
        sx={{ 
          borderRadius: 1,
          p: 2,
          pb: 0,
          width: '100%'
        }}
        image={value.image && value.image !== '' ? value.image : '/images/ratio_16_9.png'}
        alt="Event"
      />
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={1} sx={{ p: 2 }}>
          <Grid item sx={{ display: value.status === 'Cancelado' ? 'block' : 'none' }} xs={12}>
            <Alert severity="error">Evento cancelado</Alert>
          </Grid>
          <Grid item xs={6}>
            <Typography color="#373f4799" variant="body2">{formatDate(value.date.seconds * 1000)}</Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Typography color="#373f4799" variant="body2">{formatHour(value.time.seconds * 1000)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="#373f47" variant="h6"><b>{value.title}</b></Typography>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              // indicatorColor="secondary"
              // textColor="inherit"
              variant="fullWidth"
              sx={{ display: value.status === 'Cancelado' ? 'none' : 'block', pb: 0 }}
            >
              <Tab label="Detalles" sx={{ fontSize: '0.7rem' }} />
              <Tab label="Resultados" sx={{ fontSize: '0.7rem' }} />
              <Tab label="Información" sx={{ visibility: user.role === 'admin' ? 'visible' : 'hidden', fontSize: '0.7rem' }} />
            </Tabs>
          </Grid>
          <Grid item xs={12} sx={{ display: tabValue === 0 ? 'block' : 'none' }}>
            <Typography color="#373f4799" variant="body2">{value.description}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: tabValue === 0 ? 'block' : 'none' }}>
            <Button component={Link} endIcon={<NavigationIcon color="primary"/>} fullWidth href={`https://www.google.com/maps/search/?api=1&query=${value.place}`} sx={{ background: '#373f470a', color: '#444', mt: 2 }} target="_blank">
              Ir a Google Maps
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ display: tabValue === 0 ? 'block' : 'none' }}>
            <ListItem sx={{ p: 1 }}>
              <ListItemIcon sx={{ minWidth: '36px' }}>
                <PlaceOutlinedIcon color="primary" sx={{ opacity: 0.8 }} />
              </ListItemIcon>
              <ListItemText
                disableTypography
                primary={
                  // <Typography color="#373f4755" variant="caption">{value.place}</Typography>
                  <Typography
                    color="#373f47cc"
                    component="div"
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                    }}
                    variant="caption"
                  >
                    { value.place }
                  </Typography>
                }
              />
            </ListItem>
          </Grid>
          <Grid item xs={12} align="middle" sx={{ display: tabValue === 0 ? 'block' : 'none' }}>
            <Box sx={{ width: '100%' }}>
              <img
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${value.place}&markers=color:red%7C${value.place}&zoom=16&format=png&size=600x300&map_id=9b73e52bd8db4e81&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
                alt="Mapa"
                style={{ borderRadius: 8, verticalAlign: 'middle', width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ display: (tabValue === 0 && value.status !== 'Cancelado') ? 'block' : 'none', mt: 1}}>
            {/* <Button component={Link} disabled={registered || user.role === 'admin'} disableElevation fullWidth href={`https://api.whatsapp.com/send?phone=+523112631763&text=${message}`} onClick={checkout} size="large" target="_blank" variant="contained"> */}
            <Button disabled={registered || user.role === 'admin' || isTryingToPay || !canRegister} disableElevation fullWidth onClick={checkout} size="large" variant="contained">
              {registered ? 'Ya estás inscrito' : 'Inscribirse'}
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ display: (user.role === 'admin' && tabValue === 0 && value.status !== 'Cancelado') ? 'block' : 'none', mt: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Button disableElevation fullWidth onClick={() => setOpenCancel(true)}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button disableElevation fullWidth onClick={handleOpenEdit} variant="contained">
                  Editar
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {
            formattedParticipants ? (
              <Grid item xs={12} sx={{ display: tabValue === 2 ? 'block' : 'none' }}>
               <Information eventId={value.id}/>
              </Grid>
            ) : null
          }
          {
            participants && !eventWinners ? (
              <Grid item xs={12} sx={{ display: (user.role === 'admin' && tabValue === 1) ? 'block' : 'none' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography color="#373f47dd" component="p" variant="body1" gutterBottom sx={{ pb: 1 }}><b>Primeros lugares</b></Typography>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={formattedParticipants}
                      renderInput={(params) => <TextField {...params} />}
                      value={firstOneWinner}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, newValue) => {
                        setFirstOneWinner(newValue)
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={formattedParticipants}
                      renderInput={(params) => <TextField {...params} />}
                      value={firstTwoWinner}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, newValue) => {
                        setFirstTwoWinner(newValue)
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <Typography color="#373f47dd" component="p" variant="body1" gutterBottom sx={{ pb: 1 }}><b>Segundos lugares</b></Typography>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={formattedParticipants}
                      renderInput={(params) => <TextField {...params} />}
                      value={secondOneWinner}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, newValue) => {
                        setSecondOneWinner(newValue)
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={formattedParticipants}
                      renderInput={(params) => <TextField {...params} />}
                      value={secondTwoWinner}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, newValue) => {
                        setSecondTwoWinner(newValue)
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                  <Typography color="#373f47dd" component="p" variant="body1" gutterBottom sx={{ pb: 1 }}><b>Terceros lugares</b></Typography>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={formattedParticipants}
                      renderInput={(params) => <TextField {...params} />}
                      value={thirdOneWinner}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, newValue) => {
                        setThirdOneWinner(newValue)
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={formattedParticipants}
                      renderInput={(params) => <TextField {...params} />}
                      value={thirdTwoWinner}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, newValue) => {
                        setThirdTwoWinner(newValue)
                      }}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button disabled={!canBeSaved} disableElevation fullWidth onClick={saveWinners}variant="contained">
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : null
          }
          {
            eventWinners ? (
              <Grid item xs={12} sx={{ display: (tabValue === 1) ? 'block' : 'none' }}>
                <Grid container spacing={2}>
                {
                  Object.values(eventWinners.winners).map((winner, index) => {
                    return (
                      <Grid key={`${winner.id}_${index}`} item xs={12}>
                        <Winner date={eventWinners.date} index={index} winner={winner}/>
                      </Grid>
                    )
                  })
                }
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ display: (user.role === 'user' && tabValue === 1) ? 'block' : 'none' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Empty message="No hay resultados disponibles" />
                  </Grid>
                </Grid>
              </Grid>
            )
          }
        </Grid>
      </CardContent>
      <Dialog
        open={openCancel}
        onClose={() => setOpenCancel(false)}
      >
        <DialogTitle>
          { `¿Seguro que deseas cancelar ${value.title}?` }
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenCancel(false)}>Ahora no</Button>
          <Button onClick={onCancel} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openEdit} onClose={handleCloseEdit}>
        <DialogTitle>Editar Evento</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <CreateEvent create={edit} edit={value} onCreateEvent={onEditEvent} />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button disableElevation onClick={handleCloseEdit}>Cancelar</Button>
          <Button color='primary' disableElevation variant='contained' onClick={onEdit}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default Summary