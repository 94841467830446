import React from 'react'

import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material'

const RankingItem = (props) => {
  const { index, value } = props

  return (
    <Card sx={{ p: 1 }}>
      <CardContent sx={{ '&:last-child': { pb: 0 }, p: 0 }}>
        <Grid container spacing={0}>
          <Grid item xs={1}>
            <Typography variant="body2" gutterBottom>
              <b>{index + 1}</b>
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" gutterBottom>
              {value.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default RankingItem