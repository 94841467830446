import React, { useEffect } from 'react'

import useAuth from 'hooks/auth'

// import { useTheme } from '@mui/material/styles'
// import useMediaQuery from '@mui/material/useMediaQuery'

import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material'

// import { MX } from 'country-region-data/dist/data.js'

import { DataGrid, esES } from '@mui/x-data-grid'

const formatDate = (date) => {
  var options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Date(date).toLocaleDateString('es-MX', options)
}

const Users = () => {
  // const theme = useTheme()
  // const matches = useMediaQuery(theme.breakpoints.up('lg'))

  const { getUsers, users } = useAuth()

  const columns = [
    // { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 200,
    },
    // {
    //   field: 'nickname',
    //   headerName: 'Apodo',
    //   width: 160,
    // },
    {
      field: 'email',
      headerName: 'Email',
      width: 260,
    },
    // {
    //   field: 'region',
    //   headerName: 'Estado',
    //   width: 200,
    //   valueFormatter: (params) => {
    //     const valueFormatted = params.value ? MX[2].filter(reg => reg[1] === params.value)[0][0] : ''
    //     return `${valueFormatted}`
    //   },
    // },
    {
      field: 'createdAt',
      headerName: 'Creado el',
      width: 200,
      valueFormatter: (params) => {
        return `${formatDate(params.value.seconds * 1000)}`
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Actualizado el',
      width: 200,
      valueFormatter: (params) => {

        return `${params.value ? formatDate(params.value.seconds * 1000) : ''}`
      },
    },
  ]

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent sx={{ '&:last-child': { pb: 2 }, p: 2, width: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Lista de Usuarios
            </Typography>
            <DataGrid
              autoHeight
              // disableColumnMenu
              columns={columns}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              pageSize={5}
              rows={users}
              rowsPerPageOptions={[5]}
              sx={{ borderWidth: 0 }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Users