import React, { useState } from 'react'

import useAuth from 'hooks/auth'

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography
} from '@mui/material'

// Blue 6c91c2
// Brighter blue 228cdb
// Green a2c5ac

const Forgot = (props) => {

  const { resetPassword } = useAuth()

  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  const sendPasswordResetEmail = async () => {
    resetPassword(email)
    setEmailSent(true)
  }

  return (
    <Grid container spacing={0}>
      {/* <Grid item xs={12} md={5} lg={6} sx={{ background: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23000000' fill-opacity='0.04' fill-rule='evenodd'/%3E%3C/svg%3E")`, backgroundColor: 'white', display: { xs: 'none', md: 'flex' }  }}> */}
      <Grid item xs={12} md={5} lg={6} sx={{ background: 'url(fmfta/background-signin.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', display: { xs: 'none', md: 'flex' }  }}>
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={12} align="middle">
            <Box sx={{ p: 0, width: '100%' }}>
              <img
                src="/images/fmft-logo.png"
                alt="FMFTA Logo"
                style={{ opacity: 0.9, verticalAlign: 'middle', width: '60%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} lg={6}>
        <Grid container spacing={0} alignItems="center" justify="center" sx={{ background: 'white', minHeight: '100vh', p: { xs: 0, md: 8 } }}>
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                      ¿Olvidaste tu contraseña?
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      { emailSent ? `Por favor revisa tu correo, hemos mandado las instrucciones para reestablecer tu contraseña a ${email}.` : 'No te preocupes, te mandaremos las instrucciones para reestablecerla.' }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ display: emailSent ? 'none' : 'block' }}>
                    <TextField
                      id="outlined-email-input"
                      InputLabelProps={{ shrink: true }}
                      label="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="usuario@fmfta.mx"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ display: emailSent ? 'none' : 'block' }}>
                    <Grid container direction="row" justifyContent="flex-end">
                      <Button disabled={email === ''} variant="contained" disableElevation onClick={sendPasswordResetEmail}>
                        Reestablecer
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ pt: 4 }} variant="body1">
                      <Link sx={{ fontWeight: 'bolder', textDecoration: 'none' }} href="/signin">Volver a inicio de sesión</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Forgot