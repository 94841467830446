import React, { useEffect, useState } from 'react'

import useAuth from 'hooks/auth'

import {
  Chip,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@mui/material'

const formatDate = (date) => {
  var options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Date(date).toLocaleDateString('es-MX', options)
}

const formatHour = (date) => {
  var options = { hour: 'numeric', minute: 'numeric' }
  return new Date(date).toLocaleTimeString('es-MX', options)
}

const Event = (props) => {
  const { onEventSelected, value } = props

  const { amIRegistered } = useAuth()

  const [registered, setRegistered] = useState(null)

  useEffect(() => {
    const isRegistered = async () => {
      const registered = await amIRegistered(value.id)
      setRegistered(registered)
    }

    isRegistered()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <CardActionArea onClick={() => onEventSelected(value)}>
      <Card sx={{ display: { xs: 'block', md: 'flex' }, p: 2 }}>
        <CardMedia
          component="img"
          sx={{ 
            borderRadius: 1,
            height: { md: '128px', lg: '128px' },
            width: { xs: '100%', md: '128px', lg: '128px' }
          }}
          image={value.image && value.image !== '' ? value.image : '/images/ratio_16_9.png'}
          alt="Event"
        />
        <CardContent 
          sx={{ 
            '&:last-child': { pb: 0 },
            p: 0, 
            pl: { xs: 0, md: 2 }, 
            pt: { xs: 2, md: 0 }, 
          }}
        >
          <Typography color="#aaaaaa" variant="subtitle2" gutterBottom>
            { formatDate(value.date.seconds * 1000) } • { formatHour(value.time.seconds * 1000) }
          </Typography>
          <Typography variant="body2" gutterBottom>
            { value.title }
          </Typography>
          <Typography 
            color="#bdbdbd"
            component="div"
            sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                // textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
            }}
            variant="caption"
          >
            { value.place }
          </Typography>
          <Chip label="Cancelado" size="small" sx={{ backgroundColor: '#A63446', borderRadius: 1, color: '#ffffff', display: value.status === 'Cancelado' ? '' : 'none', mt: 1 }} />
          <Chip label="Inscrito" size="small" sx={{ backgroundColor: '#7FB285', borderRadius: 1, color: '#ffffff', display: registered ? '' : 'none', mt: 1 }} />
        </CardContent>
      </Card>
    </CardActionArea>
  )
}

export default Event