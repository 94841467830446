import React, { useEffect } from 'react'

import useAuth from 'hooks/auth'

import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material'

import { DataGrid, esES } from '@mui/x-data-grid'

const List = () => {
  const { getInvitesByUid, invites } = useAuth()

  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      width: 260,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Estado',
    },
    // {
    //   field: 'createdAt',
    //   headerName: 'Creado el',
    //   width: 200,
    //   valueFormatter: (params) => {
    //     return `${formatDate(params.value.seconds * 1000)}`
    //   },
    // },
    // {
    //   field: 'updatedAt',
    //   headerName: 'Actualizado el',
    //   width: 200,
    //   valueFormatter: (params) => {
    //     return `${params.value ? formatDate(params.value.seconds * 1000) : ''}`
    //   },
    // },
  ]

  useEffect(() => {
    getInvitesByUid()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card sx={{ p: 2 }}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              <b>Lista de Amigos</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              // disableColumnMenu
              columns={columns}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              pageSize={5}
              rows={invites}
              rowsPerPageOptions={[5]}
              sx={{ borderWidth: 0 }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default List