import React from 'react'

import {
  Container,
  Grid,
  Typography
} from '@mui/material'

const WhatWeDo = (props) => {
  const { id } = props
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ my: 4, mb: 8, py: 0 }} alignItems='center'>
          <Grid item xs={12}>
            <Typography id={id} align='center' variant="h4" gutterBottom>
              <b>¿QUÉ HACEMOS?</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <ul>
                <li>
                  <p>Impartimos entrenamientos de Fútbol Tenis en beneficio del desarrollo del deporte para todos y todas desde las categorías amateur hasta seleccionados nacionales.</p>
                </li>
                <li>
                  <p>Desarrollamos torneos amateur en diferentes espacios dentro de nuestro territorio nacional.</p>
                </li>
                <li>
                  <p>Desarrollamos todo tipo ligas de Fútbol Tenis que  van desde inter-clubes, inter-universitarias, amateur y la liga profesional.</p>
                </li>
                <li>
                  <p>Buscamos contar con los mejores futboltenistas del país, por lo que cada participante de nuestros torneos será rankeado y tendrá la oportunidad de representar a México en los torneos internacionales.</p>
                </li>
                <li>
                  <p>Ofrecemos eventos a empresas e instituciones de gobierno donde impartimos entrenamientos, retas, retos, torneos y talleres de valores, educación, salud y desarrollo integral por medio de futbolistas y exfutbolistas profesionales.</p>
                </li>
                <li>
                  <p>Comercializamos los kits oficiales de Fútbol Tenis para que más gente pueda llegar a nuestro deporte y con ello ejercitarse de manera recreativa y/o profesional.</p>
                </li>
                <li>
                  <p>Estamos convencidos que nuestro deporte puede ser una herramienta sustancial para combatir la delincuencia, las adicciones, promover y participar en el desarrollo físico y psicológico de nuestro país por lo que hacemos eventos con causa social.</p>
                </li>
                <li>
                  <p>Replicamos este modelo en cada uno de los estados de la República Mexicana a través de nuestros líderes territoriales.</p>
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default WhatWeDo