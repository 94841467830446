import { useEffect, useState } from 'react'

import { initializeApp } from 'firebase/app'

import {
  createUserWithEmailAndPassword,
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
  PhoneAuthProvider,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
  where
} from 'firebase/firestore'

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes
} from 'firebase/storage'

import { 
  getFunctions,
  httpsCallable
} from 'firebase/functions'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(config)
const db = getFirestore(app)
const functions = getFunctions(app)
const storage = getStorage(app)

export const auth = getAuth(app)

export const useProvideFirebase = () => {
  const [currentUser, setCurrentUser] = useState()
  const [user, setUser] = useState()
  const [users, setUsers] = useState([])
  const [events, setEvents] = useState([])
  const [myEvents, setMyEvents] = useState([])
  const [loading, setLoading] = useState(true)

  const [achievements, setAchievements] = useState([])
  const [participants, setParticipants] = useState([])
  const [eventWinners, setEventWinners] = useState(null)
  const [winners, setWinners] = useState([])
  const [orders, setOrders] = useState([])

  const createUserProfile = async (user, provider, ref) => {
    const docRef = doc(db, 'users', user.uid)
    const docSnap = await getDoc(docRef)

    const { displayName, email, emailVerified, photoURL, uid } = user
    
    if (!docSnap.exists()) {
      await setDoc(docRef, {
        authProvider: provider,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        email: email,
        emailVerified: emailVerified,
        name: displayName,
        photoUrl: photoURL,
        role: 'user',
        uid: uid,
      })
    }

    if (ref) {
      if (ref === 'ClasificatorioColombia') {
        await addQRRegister(user, displayName, ref)
      } else {
        await updateInvite(ref, displayName)
      }
    }
  }

  const signInWithGoogle = async (ref, analytics) => {
    try {
      const provider = new GoogleAuthProvider()
      const result = await signInWithPopup(auth, provider)
      const user = result.user
      createUserProfile(user, 'google', ref)

      if (analytics) {
        const { id, metadata } = analytics

        const docRef = doc(db, 'users', user.uid)
        const docSnap = await getDoc(docRef)

        if (!docSnap.exists()) return

        const { email, firstName, lastName, uid } = docSnap.data()
        const usr = { email, firstName, lastName, uid }
        await analyticsFor(id, metadata, usr)
      }
      
    } catch (error) {
      console.error(error)
    }
  }

  const signInWithFacebook = async (ref, analytics) => {
    try {
      const provider = new FacebookAuthProvider()
      // provider.addScope('user_birthday')
      const result = await signInWithPopup(auth, provider)
      const user = result.user
      createUserProfile(user, 'facebook', ref)

      if (analytics) {
        const { id, metadata } = analytics

        const docRef = doc(db, 'users', user.uid)
        const docSnap = await getDoc(docRef)

        if (!docSnap.exists()) return

        const { email, firstName, lastName, uid } = docSnap.data()
        const usr = { email, firstName, lastName, uid }
        await analyticsFor(id, metadata, usr)
      }

      if (!user.emailVerified) {
        await sendEmailVerification(user)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const signUpWithEmailAndPassword = async (user, password, ref, analytics) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, user.email, password)
      const credentials = result.user

      const docRef = doc(db, 'users', credentials.uid)
      const docSnap = await getDoc(docRef)

      const name = `${user.firstName} ${user.lastName}`
      
      if (!docSnap.exists()) {
        user['authProvider'] = 'email'
        user['createdAt'] = serverTimestamp()
        user['updatedAt'] = serverTimestamp()
        user['name'] = name
        user['role'] = 'user'
        user['uid'] = credentials.uid
        await setDoc(docRef, user)
      }

      if (!credentials.emailVerified) {
        await sendEmailVerification(credentials)
      }

      if (ref) {
        if (ref === 'ClasificatorioColombia') {
          await addQRRegister(user, name, ref)
        } else {
          await updateInvite(ref, name)
        }
      }

    } catch (error) {
      console.error(error)
    }
  }

  const signInWithEmail = async (email, password, analytics) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password)
      const user = result.user

      if (analytics) {
        const { id, metadata } = analytics

        const docRef = doc(db, 'users', user.uid)
        const docSnap = await getDoc(docRef)

        if (!docSnap.exists()) return

        const { email, firstName, lastName, uid } = docSnap.data()
        const usr = { email, firstName, lastName, uid }
        await analyticsFor(id, metadata, usr)
      }
    } catch (error) {
      return error
    }
  }

  const verifyEmail = async () => {
    try {
      await sendEmailVerification(currentUser)
    } catch (error) {
      console.error(error)
    }
  }

  const requestCode = async (phone) => {
    const provider = new PhoneAuthProvider(auth)

    const verifier = new RecaptchaVerifier('verify-button', {
      'size': 'invisible',
      'callback': (response) => {}
    }, auth)

    return await provider.verifyPhoneNumber(phone, verifier)
  }

  const verifyPhone = async (verificationId, code) => {
    // const provider = new PhoneAuthProvider(auth)
    // const credential = PhoneAuthProvider.credential(verificationId, code)
    // const result = await linkWithCredential(auth.currentUser, credential)
    // try {
    //   const code = '' // Prompt the user for the verification code
    //   await updatePhoneNumber(
    //     auth.currentUser,
    //     PhoneAuthProvider.credential(verificationId, code)
    //   )
    // } catch (e) {
    //   if (e.code === 'auth/account-exists-with-different-credential') {
    //     const cred = PhoneAuthProvider.credentialFromError(e);
    //     await linkWithCredential(auth.currentUser, cred);
    //   }
    // }
  }

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email)
    } catch (error) {
      console.error(error)
    }
  }
  
  const logout = async () => {
    try {
      await signOut(auth)
    } catch (error) {
      console.error(error)
    }
  }
  
  const getUserProfile = async () => {
    const docRef = doc(db, 'users', currentUser.uid)
    const docSnap = await getDoc(docRef)
    return docSnap.data()
  }

  const updateUserProfile = async (profile) => {
    const docRef = doc(db, 'users', user.uid)
    console.log(profile.birthday)
    profile['birthday'] = Timestamp.fromDate(profile.birthday)
    profile['updatedAt'] = serverTimestamp()

    const image = profile['photoUrl']

    if (image instanceof File) {
      const storageRef = ref(storage, `profiles/${user.uid}`)
      const snap = await uploadBytes(storageRef, image)
      const imageUrl = await getDownloadURL(snap.ref)
      profile['photoUrl'] = imageUrl
    } else {
      delete profile['photoUrl']
    }

    console.log(profile)

    await updateDoc(docRef, profile)
  }

  const getUsers = async () => {
    const q = collection(db, 'users')
    onSnapshot(q, (snap) => {
      const results = []
      snap.forEach((doc) => {
        results.push({
          ...doc.data(),
          id: doc.id
        })
      })
      setUsers(results)
    })
  }

  const uploadImage = async (eventId, image) => {
    const storageRef = ref(storage, `events/${eventId}`)
    const snap = await uploadBytes(storageRef, image)
    return await getDownloadURL(snap.ref)
  }

  const createEvent = async (event) => {
    event['createdAt'] = serverTimestamp()
    event['updatedAt'] = serverTimestamp()
    event['status'] = 'Creado'
    
    const image = event['image']
    delete event['image']
    
    const eventRef = await addDoc(collection(db, 'events'), event)
    const eventId = eventRef.id

    var imageUrl = ''
    if (image instanceof File) {
      imageUrl = await uploadImage(eventId, image)
    } else {
      imageUrl = 'https://firebasestorage.googleapis.com/v0/b/fmftamx.appspot.com/o/default%2Fratio_16_9.png?alt=media&token=dad6c3f8-6bf2-448e-82e4-90ac56e3ee8d'
    }

    await updateDoc(doc(db, 'events', eventId), {
      'image': imageUrl,
    })
  }

  const updateEvent = async (eventId, event) => {
    event['updatedAt'] = serverTimestamp()

    const image = event['image']

    if (image instanceof File) {
      const imageUrl = await uploadImage(eventId, image)
      event['image'] = imageUrl
    } else {
      delete event['image']
    }

    await updateDoc(doc(db, 'events', eventId), event)
  }

  const amIRegistered = async (eventId) => {
    const docRef = doc(db, 'participants', eventId)
    const docSnap = await getDoc(docRef)
  
    return docSnap.exists() ? Object.keys(docSnap.data()).includes(currentUser.uid) : false
  }

  const cancelEvent = async (id, name) => {
    await updateDoc(doc(db, 'events', id), {
      'status': 'Cancelado',
      'updatedAt': serverTimestamp()
    })
    const docRef = doc(db, 'participants', id)
    const docSnap = await getDoc(docRef)
    const participants = docSnap.data()

    const emailTo = []
    Object.values(participants).forEach((participant) => {
      emailTo.push(participant.email)
    })

    sendCancelationEmail(emailTo, name)
  }

  
  const sendCancelationEmail = async (emailTo, name) => {
    const sendCancelation = httpsCallable(functions, 'sendCancelation')
    const result = await sendCancelation({
      'name': name,
      'emailTo': emailTo
    })
    return result.data
  }

  const deleteEvent = async (id) => {
    await deleteDoc(doc(db, 'events', id))
  }

  const getEvents = async () => {
    const q = collection(db, 'events')
    onSnapshot(q, (snap) => {
      const results = []
      snap.forEach((doc) => {
        results.push({
          ...doc.data(),
          id: doc.id
        })
      })
      setEvents(results)
    })
  }

  const registerToEvent = async (event) => {
    const { amount, description, id, image, title } = event
    const { email, firstName, lastName, uid } = user

    const orderId = await addOrder(id, title, amount)

    const paymentCheckout = httpsCallable(functions, 'paymentCheckout')
    
    const result = await paymentCheckout({
      'event': {
        'amount': amount,
        'description': description,
        'image': image,
        'id': id,
        'name': title,
      },
      'order': {
        'id': orderId,
      },
      'user': {
        'email': email,
        'firstName': firstName,
        'lastName': lastName,
        'uid': uid
      }
    })

    return result.data
  }

  const addOrder = async (eventId, eventName, eventAmount) => {
    const ordersRef = collection(db, 'orders')
    const { email, firstName, lastName, uid } = user
    
    const order = {
      eventAmount: eventAmount,
      createdAt: serverTimestamp(),
      email: email,
      eventId: eventId,
      eventName: eventName,
      name: `${firstName} ${lastName}`,
      status: 'Creada',
      uid: uid,
      updatedAt: serverTimestamp()
    }

    const ref = await addDoc(ordersRef, order)

    return ref.id
  }

  const getParticipantsById = async (eventId) => {
    const q = doc(db, 'participants', eventId)
    onSnapshot(q, (doc) => {
      setParticipants(Object.values(doc.data()))
    })
  }

  const addWinners = async (event, winners) => {
    const { date, id, title } = event

    const winnersRef = collection(db, 'winners')
    const docRef = doc(winnersRef, id)

    await setDoc(docRef, {
      'date': date,
      'id': id,
      'name': title,
      'winners': winners
    })
  }

  const getWinners = async () => {
    const q = collection(db, 'winners')
    onSnapshot(q, (snap) => {
      const results = {}
      snap.forEach((doc) => {
        const { winners } = doc.data()
        
        winners.forEach((winner, index) => {
          const { id, name, email } = winner
          const weight = index * 10

          results[id] = {
            'count': results[id] && results[id].count ? results[id].count + weight : weight,
            'email': email,
            'id': id,
            'name': name,
          }
        })
      })
      setWinners(Object.values(results).sort((a, b) => b.count - a.count))
    })
  }

  const getWinnersById = async (eventId) => {
    const q = doc(db, 'winners', eventId)
    onSnapshot(q, (doc) => {
      setEventWinners(doc.data())
    })
  }

  const getWinnersByUserId = async () => {
    const q = collection(db, 'winners')
    onSnapshot(q, (snap) => {
      const results = []
      snap.forEach((doc) => {
        const result = doc.data().winners
          .filter((winner) => winner.id === user.uid)
        
        if (result.length > 0) {
          results.push({
            ...doc.data(),
            id: doc.id
          })
        }
      })
      setAchievements(results)
    })
  }

  const getOrders = async (eventId) => {
    const q = query(collection(db, 'orders'), where('eventId', '==', eventId))
    onSnapshot(q, (snap) => {
      const results = []
      snap.forEach((doc) => {
        results.push({
          ...doc.data(),
          id: doc.id
        })
      })
      setOrders(results)
    })
  }

  const getMyEvents = async () => {
    const docRef = doc(db, 'myevents', user.uid)
    onSnapshot(docRef, async (snap) => {
      const data = snap.data()
      const ids = Object.keys(data)
      
      const newEvents = []
      ids.forEach(async (id) => {
        const docSnap = await getDoc(doc(db, 'events', id))
        const event = docSnap.data()
        newEvents.push({
          ...event,
          id: id
        })
        setMyEvents(newEvents.sort((a, b) => b.date.seconds - a.date.seconds).slice(0, 5))
      })
    })
  }

  const checkIfEmailExists = async (email) => {
    const q = query(collection(db, 'users'), where('email', '==', email))
    const docSnap = await getDocs(q)
    return !docSnap.empty
  }

  const sendInvitationEmail = async (emailTo) => {
    const { email, firstName, lastName, uid } = user

    const emailExists = await checkIfEmailExists(emailTo)

    if (!emailExists) {
      const ref = await addInvite(uid, emailTo)
      const sendInvitation = httpsCallable(functions, 'sendInvitation')
      await sendInvitation({
        'email': email,
        'name': `${firstName} ${lastName}`,
        'emailTo': emailTo,
        'ref': ref
      })
      // return result.data

      return {
        'success': true,
        'message': 'El correo fue enviado exitosamente'
      }
    } else {
      return {
        'success': false,
        'error': 'El correo ya está registrado'
      }
    }

    
  }

  const addInvite = async (uid, email) => {
    const invitesRef = collection(db, 'invites')
    
    const invite = {
      createdAt: serverTimestamp(),
      email: email,
      status: 'Enviada',
      uid: uid,
      updatedAt: serverTimestamp()
    }

    const ref = await addDoc(invitesRef, invite)

    return ref.id
  }

  const updateInvite = async (id, name) => {
    const docRef = doc(db, 'invites', id)
    await updateDoc(docRef, {
      'name': name,
      'status': 'Registrado'
    })
  }

  const addQRRegister = async (user, name, ref) => {
    const { email, uid } = user
    const docRef = collection(db, 'qr')
    
    const register = {
      code: ref,
      createdAt: serverTimestamp(),
      email: email,
      name: name,
      uid: uid,
      updatedAt: serverTimestamp()
    }

    await addDoc(docRef, register)
  }

  const confirmParticipant = async (participant) => {
    const { eventId, uid } = participant
    const participantRef = doc(db, 'participants', eventId)
    participant['status'] = 'Confirmado'
    await updateDoc(participantRef, {
      [uid]: participant
    })

    const myEventRef = doc(db, 'myevents', uid)
    participant['status'] = 'Confirmado'
    await updateDoc(myEventRef, {
      [eventId]: participant
    })
  }

  const [invites, setInvites] = useState()
  const getInvitesByUid = async () => {
    const { uid } = user
    const q = query(collection(db, 'invites'), where('uid', '==', uid))
    onSnapshot(q, (snap) => {
      const results = []
      snap.forEach((doc) => {
        results.push({
          ...doc.data(),
          id: doc.id
        })
      })
      setInvites(results)
    })
  }

  useEffect(() => {
    const getProfile = async (uid) => {
      const docRef = doc(db, 'users', uid)
      onSnapshot(docRef, doc => {
        setUser(doc.data())
      })
    }

    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user)
      if (user) {
        getProfile(user.uid)
      }
      setLoading(false)
    })
    return unsubscribe
  }, [])

  const analyticsFor = async (id, metadata, usr = undefined) => {
    const docRef = collection(db, 'analytics')

    metadata['browser']['major'] = metadata.browser.major ? metadata.browser.major : 'N/A'
    metadata['browser']['name'] = metadata.browser.name ? metadata.browser.name : 'N/A'
    metadata['browser']['version'] = metadata.browser.version ? metadata.browser.version : 'N/A'
    
    metadata['cpu']['architecture'] = metadata.cpu.architecture ? metadata.cpu.architecture : 'N/A'
    
    metadata['device']['model'] = metadata.device.model ? metadata.device.model : 'N/A'
    metadata['device']['type'] = metadata.device.type ? metadata.device.type : 'N/A'
    metadata['device']['vendor'] = metadata.device.vendor ? metadata.device.vendor : 'N/A'

    metadata['engine']['name'] = metadata.engine.name ? metadata.engine.name : 'N/A'
    metadata['engine']['version'] = metadata.engine.version ? metadata.engine.version : 'N/A'

    metadata['os']['name'] = metadata.os.name ? metadata.os.name : 'N/A'
    metadata['os']['version'] = metadata.os.version ? metadata.os.version : 'N/A'
    
    metadata['ua'] = metadata.ua ? metadata.ua : 'N/A'

    if (usr !== undefined) {
      metadata['user'] = usr
    }
    
    console.log(metadata)
    
    const info = {
      createdAt: serverTimestamp(),
      id: id,
      metadata: metadata,
      updatedAt: serverTimestamp()
    }

    await addDoc(docRef, info)
  }

  return {
    achievements,
    addWinners,
    amIRegistered,
    analyticsFor,
    cancelEvent,
    confirmParticipant,
    createEvent,
    currentUser,
    deleteEvent,
    events,
    eventWinners,
    getEvents,
    getInvitesByUid,
    getMyEvents,
    getOrders,
    getParticipantsById,
    getUserProfile,
    getUsers,
    getWinners,
    getWinnersById,
    getWinnersByUserId,
    invites,
    loading,
    logout,
    myEvents,
    orders,
    participants,
    registerToEvent,
    requestCode,
    resetPassword,
    sendInvitationEmail,
    signInWithEmail,
    signUpWithEmailAndPassword,
    signInWithFacebook,
    signInWithGoogle,
    updateEvent,
    updateUserProfile,
    user,
    users,
    verifyEmail,
    verifyPhone,
    winners,
  }
}
