import React from 'react'

import { useNavigate  } from 'react-router-dom'

import {
  Box,
  Button,
  Container,
  Grid,
  Typography
} from '@mui/material'

const Head = (props) => {
  const { id } = props

  const navigate = useNavigate()

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ p: 2, py: 8 }} justify='center' alignItems='center'>
          <Grid item xs={12} md={6} lg={7} order={{ xs: 2, md: 1, lg: 1}}>
            <Typography id={id} variant="h3" component="div" gutterBottom>
              <b>Federación Mexicana de Fútbol Tenis Asociación</b>
            </Typography>
            <Typography variant="h6" component="div" gutterBottom>
              Participa en nuestros torneos nacionales, internacionales, entrenamientos, eventos y demás actividades de la FMFT.
            </Typography>
            <Button
              size="large"
              variant="contained"
              disableElevation
              color="secondary"
              onClick={() => navigate('/signup')}
              sx={{ my: 4, display: 'block' }}
            >
              Regístrate
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={5} order={{ xs: 1, md: 2, lg: 2 }}>
            <Box sx={{ px: 2, width: '100%' }}>
              <img
                src='images/fmft-logo.png'
                alt="FMFT Logo"
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default Head