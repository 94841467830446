import React from 'react'

import {
  Container,
  Grid,
  Typography
} from '@mui/material'

const Footer = () => {
  return (
    <React.Fragment>
      <Container sx={{ color: 'white', my: 3, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <Typography variant="subtitle1" component="div" gutterBottom>
              © 2022 Federación Mexicana de Fútbol Tenis.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant="subtitle2" component="div" gutterBottom>
              <a href="https://docs.google.com/document/d/1nXC3jSfsPJvJ6NjmSKGE7OO2DthvlR70xTP9FEqvhm8/edit" rel="noreferrer" style={{ color: 'white', textDecoration: 'none' }} target="_blank">
                Aviso de privacidad
              </a>
            </Typography>
            <Typography variant="subtitle2" component="div" gutterBottom>
              <a href="https://docs.google.com/document/d/16r-p1NS8YNbq_e58pJm694wJ2o2qGW0Xah3kA4mL2vk/edit" rel="noreferrer" style={{ color: 'white', textDecoration: 'none' }} target="_blank">
                Términos y condiciones
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default Footer