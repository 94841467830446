import React from 'react'

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material'

const Ads = (props) => {

  const { description, image, kit, message } = props

  return (
    <Card sx={{ p: 4 }}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4} align="middle">
            <Box sx={{ pt: 0, width: '100%' }}>
              <img
                src={image}
                alt="Referral"
                style={{ filter: 'grayscale(0.4)', opacity: '0.9', verticalAlign: 'middle', width: '70%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
            {/* <Typography variant="h5" sx={{ fontFamily: 'Batsand', mb: 2 }}> */}
              {description}
            </Typography>
            {
              kit ? (
                <Grid container spacing={1}>
                  <Grid item>
                    <Button component="a" href="https://www.amazon.com.mx/dp/B09C13VVFP" target="_blank" color="primary" disableElevation size="small" variant="contained">
                      Amazon
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button component="a" href="https://articulo.mercadolibre.com.mx/MLM-1894609013-cancha-de-futbol-tenis-oficial-federacion-mexicana-fmfta-_JM" target="_blank" color="primary" disableElevation size="small" variant="contained">
                      Mercado Libre
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Button component="a" href={`https://api.whatsapp.com/send?phone=+525626574935&text=${message}`} target="_blank" color="primary" disableElevation size="small" variant="contained">
                  Contáctanos
                </Button>
              )
            }
          </Grid>
          {/* <Grid item xs={12} align="right">
            <Button color="primary" disableElevation size="small" variant="contained">
              Contáctanos
            </Button>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
    
  )
}

export default Ads