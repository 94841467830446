import React, { useEffect, useState } from 'react'

import useAuth from 'hooks/auth'

import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Typography
} from '@mui/material'

import Empty from 'components/empty'
import Summary from 'components/events/summary'

import ListEvent from './listevent'

const LatestEvents = (props) => {

  const { title, type } = props

  const { events, getEvents, user } = useAuth()

  const [eventsList, setEventsList] = useState([])

  useEffect(() => {
    getEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const near = type === 'near'   ? events.filter(item => user.region && item.region.code === user.region.code) : events

    const filtered = near.filter((event) => {
      const time = (new Date(event.date.seconds * 1000)).getTime()
      return time >= Date.now()
    })
    .sort((a, b) => a.date.seconds - b.date.seconds)

    setEventsList(filtered)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events])

  // const eventsList = type === 'near'   ? events.filter(item => user.region && item.region.code === user.region.code) : events

  const [openSummary, setOpenSummary] = useState(false)
  const handleCloseSummary = () => {
    setOpenSummary(false)
  }

  const [selectedEvent, setSelectedEvent] = useState(null)

  const onEventSelected = (event) => {
    setSelectedEvent(event)
    setOpenSummary(true)
  }

  return (
    <Card sx={{ p: 2 }}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {/* <Typography variant="body1" sx={{ fontFamily: 'Batsand' }}> */}
            <Typography variant="body1">
              <b>{title}</b>
            </Typography>
          </Grid>
          {
            eventsList.length > 0 ? (
              eventsList.slice(0, 9).map((item, index) => (
                <Grid key={item.id} item xs={12}>
                  <ListEvent onEventSelected={onEventSelected} value={item} />
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Empty image="/fmfta/cercanos.png" message="No hay eventos disponibles" />
              </Grid>
            )
          }
        </Grid>
      </CardContent>
      <Dialog open={openSummary} onClose={handleCloseSummary}>
        <DialogContent sx={{ p: 0 }}>
          <Summary user={user} value={selectedEvent}/>
        </DialogContent>
      </Dialog>
    </Card>
    
  )
}

export default LatestEvents