import React, { useEffect, useState } from 'react'

import {
  Link as RouterLink,
  NavLink,
  Route,
  Routes,
  useLocation
} from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  AppBar,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'

import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import MenuIcon from '@mui/icons-material/Menu'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import TwitterIcon from '@mui/icons-material/Twitter'

import Events from 'pages/shared/events'
import Edit from 'pages/dashboard/profile/edit'
import Referral from 'pages/dashboard/invites/referral'

import Home from './home'
import Invites from './invites'
import Profile from './profile'

const Dashboard = (props) => {
  const { window } = props

  const { logout, updateUserProfile, user } = useAuth()

  const formatProfilePicture = (photoUrl, provider) => {
    if (provider === 'facebook') {
      return `${photoUrl}?type=large`
    } else if (provider === 'google') {
      return photoUrl.replace('s96-c', 's600-c')
    } else {
      // return 'https://www.gravatar.com/avatar/' + md5(user.email) + '?s=200'
      return 'https://firebasestorage.googleapis.com/v0/b/fmftamx.appspot.com/o/default%2Fratio_16_9.png?alt=media&token=dad6c3f8-6bf2-448e-82e4-90ac56e3ee8d'
    }
  }

  const drawerWidth = 240

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <>
      <Toolbar>
        <Grid container>
          <Grid item xs align="middle">
            <Box sx={{ p: 0, width: '100%' }}>
              <img
                src="/images/fmft-logo-toolbar.png"
                alt="FMFTA Logo"
                style={{ opacity: 0.7, verticalAlign: 'middle', width: '70%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
      <List sx={{ p: 2 }}>
        <ListItem sx={{ py: 0, pb: 1 }}>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: 12, letterSpacing: '2px', opacity: 0.8 }}>FMFTA</Typography>
            }
          />
        </ListItem>
        <ListItem button 
          component={NavLink}
          end // https://stackoverflow.com/a/70551865
          to=""
          onClick={handleDrawerToggle}
          style={({ isActive }) => ({
            background: isActive ? '#73ab8422' : '',
            borderRadius: isActive ? '8px' : '',
            color: isActive ? '#73ab84' : '',
          })}
          sx={{
            '&:hover': {
              background: '#73ab8422',
              borderRadius: '8px',
              color: '#73ab84',
            },
            mb: 1,
            pl: 4
          }}
        >
          <ListItemText primary="Inicio" />
        </ListItem>
        <ListItem button
          component={NavLink}
          to="events"
          onClick={handleDrawerToggle}
          style={({ isActive }) => ({
            background: isActive ? '#73ab8422' : '',
            borderRadius: isActive ? '8px' : '',
            color: isActive ? '#73ab84' : '',
          })}
          sx={{
            '&:hover': {
              background: '#73ab8422',
              borderRadius: '8px',
              color: '#73ab84',
            },
            mb: 1,
            pl: 4
          }}
        >
          <ListItemText primary="Eventos" />
        </ListItem>
        <ListItem sx={{ py: 0, pb: 1 }}>
          <ListItemText
            disableTypography
            primary={
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: 12, letterSpacing: '2px', opacity: 0.8 }}>PERFIL</Typography>
            }
          />
        </ListItem>
        <ListItem button 
          component={NavLink}
          to="profile"
          onClick={handleDrawerToggle}
          style={({ isActive }) => ({
            background: isActive ? '#73ab8422' : '',
            borderRadius: isActive ? '8px' : '',
            color: isActive ? '#73ab84' : '',
          })}
          sx={{
            '&:hover': {
              background: '#73ab8422',
              borderRadius: '8px',
              color: '#73ab84',
            },
            mb: 1,
            pl: 4
          }}
        >
          <ListItemText primary="Mi Perfil" />
        </ListItem>
        <ListItem button 
          component={NavLink}
          to="invites"
          onClick={handleDrawerToggle}
          style={({ isActive }) => ({
            background: isActive ? '#73ab8422' : '',
            borderRadius: isActive ? '8px' : '',
            color: isActive ? '#73ab84' : '',
          })}
          sx={{
            '&:hover': {
              background: '#73ab8422',
              borderRadius: '8px',
              color: '#73ab84',
            },
            mb: 1,
            pl: 4
          }}
        >
          <ListItemText primary="Invitaciones" />
        </ListItem>
      </List>
      <Box sx={{ marginTop: 'auto', p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} align="center">
            <Avatar
              alt="Profile Picture"
              src={formatProfilePicture(user.photoUrl, user.authProvider)}
              sx={{ width: 64, height: 64 }}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Typography variant="body2">
              { user.name }
            </Typography>
            <Typography variant="caption">
              { user.email }
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Avatar
                  alt="Profile Picture"
                  src={formatProfilePicture(user.photoUrl, user.authProvider)}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item xs={9}>
                <Typography variant="body2">
                  { user.name }
                </Typography>
                <Typography variant="caption">
                  { user.email }
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12}>
            <Button disableElevation fullWidth size="large" variant="contained" color="primary" onClick={() => setOpenInvite(true)}>
              Invitar un amigo
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              disableElevation
              fullWidth
              onClick={logout}
              size="small"
              variant="outlined"
            >
              Cerrar sesión
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Stack direction="row" spacing={2}>
              <IconButton aria-label="facebook" component={Link} href="https://www.facebook.com/fmfutboltenis" target="_blank">
                <FacebookIcon color="primary" fontSize="inherit" />
              </IconButton>
              <IconButton aria-label="delete" component={Link} href="https://www.instagram.com/fmfutboltenis/" target="_blank">
                <InstagramIcon color="primary" fontSize="instagram" />
              </IconButton>
              <IconButton aria-label="delete" component={Link} href="https://twitter.com/fmfutboltenis" target="_blank">
                <TwitterIcon color="primary" fontSize="twitter" />
              </IconButton>
              <IconButton aria-label="linkedin" component={Link} href="https://www.linkedin.com/company/federaci%C3%B3n-mexicana-de-f%C3%BAtbol-tenis/" target="_blank">
                <LinkedInIcon color="primary" fontSize="inherit" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  const LinkRouter = (props) => <Link {...props} component={RouterLink} />

  const breadcrumbNameMap = {
    '/dashboard': 'Inicio',
    '/dashboard/events': 'Eventos',
    '/dashboard/profile': 'Perfil',
    '/dashboard/invites': 'Invitaciones',
  }

  const Page = () => {
    const location = useLocation()
    const pathnames = location.pathname.split('/').filter((x) => x)
  
    return (
      <Breadcrumbs 
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />} 
        sx={{ pb: 2 }}
      >
        <LinkRouter underline="hover" color="inherit" to="/admin">
          FMFTA
        </LinkRouter>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1
          const to = `/${pathnames.slice(0, index + 1).join('/')}`
  
          return last ? (
            <Typography color="text.primary" key={to}>
              {breadcrumbNameMap[to]}
            </Typography>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {breadcrumbNameMap[to]}
            </LinkRouter>
          )
        })}
      </Breadcrumbs>
    )
  }

  const [open, setOpen] = useState(true)
  const [save, setSave] = useState(false)

  const onSave = () => {
    setSave(true)
  }
  
  const onSaveProfile = (profile) => {
    updateUserProfile(profile)
    setSave(false)
  }

  useEffect(() => {
    if (user) {
      const completed = user.firstName && user.lastName && user.nickname && user.birthday && user.phone && user.region
      setOpen(!completed)
    }
  }, [user])

  const [openInvite, setOpenInvite] = useState(false)

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        color="inherit"
        position="fixed"
        sx={{
          boxShadow: 'none',
          display: { xs: 'flex', md: 'none' },
          ml: `${drawerWidth}px`,
          // width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            FMFTA
          </Typography>
          {/* <Box sx={{ flexGrow: 1 }}>
            
          </Box> */}
        </Toolbar>
      </AppBar>
      <Drawer
        // anchor="left"
        container={container}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: 'block', md: 'none' },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            boxSizing: 'border-box',
            width: drawerWidth
          },
          width: drawerWidth,
        }}
        variant="temporary"
      >
        { drawer }
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'none', md: 'block' },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            borderWidth: 0,
            boxSizing: 'border-box',
            width: drawerWidth
          },
          width: drawerWidth,
        }}
        variant="permanent"
        anchor="left"
      >
        { drawer }
      </Drawer>
      <Box
        component="main"
        sx={{ bgcolor: 'background.default', flexGrow: 1, p: 2 }}
      >
        <Toolbar sx={{ display: { xs: 'flex', md: 'none' } }}/>
        <Page />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="events" element={<Events />} />
          <Route path="invites" element={<Invites/>} />
          <Route path="profile" element={<Profile/>} />
        </Routes>
      </Box>
      <Dialog open={open}>
        <DialogTitle>Completa tu perfil</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom sx={{ pb: 2 }}>
            Para poder participar en los eventos, debes completar tu perfil.
          </Typography>
          <Edit user={user} save={save} onSaveProfile={onSaveProfile}/>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button color='primary' disableElevation variant='contained' onClick={onSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openInvite} onClose={() => setOpenInvite(false)}>
        <DialogContent sx={{ p: 0 }}>
          <Referral />
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default Dashboard