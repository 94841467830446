import React from 'react'

import {
  Container,
  Grid,
  Typography
} from '@mui/material'

const WhatIs = (props) => {
  const { id } = props

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ my: 4, mb: 8, py: 0 }} alignItems='center'>
          <Grid item xs={12}>
            <Typography id={id} align='center' variant="h4" gutterBottom>
              <b>¿QUÉ ES FÚTBOL TENIS?</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              Es un deporte sumamente práctico que fomenta la inclusión y que se puede jugar en cualquier superficie plana superior a los 7 metros de largo por 4 de ancho. 
            </Typography>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              Puede practicarse de manera individual, un jugador por cada lado o de manera colectiva en la que generalmente participan 2 o 3 jugadores por equipo. 
            </Typography>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              Es fácil de jugar y puede ser practicado por todos sin importar edad o género. Es muy accesible en términos económicos ya que no requiere infraestructura deportiva para ser practicado. 
            </Typography>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <b>¡Se tiene registro de que el Fútbol Tenis nació en República Checa desde hace más de 100 años!</b>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default WhatIs