import React from 'react'

import {
  Grid,
} from '@mui/material'

import List from './list'
import Referral from './referral'

const Invites = () => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={4}>
        <Referral />
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <List />
      </Grid>
    </Grid>
  )
}

export default Invites