import * as React from 'react'

import { Outlet } from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  Grid,
  Typography
} from '@mui/material'

import Ads from './ads'
import LatestEvents from './latest'
import Ranking from './ranking'

const Home = () => {
  const { user } = useAuth()

  if (!user) {
    return <Outlet />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          ¡Hola {user.firstName}!
        </Typography>
        <Typography color="#373f47aa" variant="body2">
          Aquí podrás ver toda la información sobre nuestros eventos más recientes
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Ads description="Organiza un evento privado con nosotros" image="/fmfta/vip.png" message="¡Hola! Me gustaría hacer un evento privado con ustedes."/>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Ads description="¿Te interesa participar como patrocinador?" image="/fmfta/patrocinador.png" message="¡Hola! Me gustaría participar en alguno de sus eventos."/>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Ads kit description="¿Quieres jugar fut tenis? Adquiere tu kit con nosotros" image="/fmfta/kitfmft.png" />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <LatestEvents title="Últimos eventos" />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <LatestEvents title="Eventos cercanos" type="near" />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Ranking />
      </Grid>
    </Grid>
  )
}

export default Home