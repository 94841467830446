import React, { useCallback, useEffect, useState } from 'react'

import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'

import mxLocale from 'date-fns/locale/es'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'

import InputMask from 'react-input-mask'

import data from 'country-region-data/data.json'
import { MX } from 'country-region-data/dist/data.js'

import { useDropzone } from 'react-dropzone'

const formatProfilePicture = (photoUrl, provider) => {
  if (provider === 'facebook') {
    return `${photoUrl}?type=large`
  } else if (provider === 'google') {
    return photoUrl.replace('s96-c', 's600-c')
  } else {
    // return 'https://www.gravatar.com/avatar/' + md5(user.email) + '?s=200'
    return 'https://firebasestorage.googleapis.com/v0/b/fmftamx.appspot.com/o/default%2Fratio_16_9.png?alt=media&token=dad6c3f8-6bf2-448e-82e4-90ac56e3ee8d'
  }
}

const Edit = (props) => {

  const { onSaveProfile, save, user } = props

  const [userEdit, setUserEdit] = useState({
    'firstName': user.firstName ? user.firstName : user.name,
    'lastName': user.lastName ? user.lastName : '',
    'nickname': user.nickname ? user.nickname : '',
    'gender': user.gender ? user.gender : '',
    'phone': user.phone ? user.phone : '',
    'region': user.region ? user.region.code : '',
  })

  console.log(userEdit)

  const handleChange = (prop) => (event) => {
    setUserEdit({ ...userEdit, [prop]: event.target.value })
  }

  const [birthday, setBirthday] = useState(user.birthday ? new Date(user.birthday.seconds * 1000) : null)

  const [file, setFile] = useState('')
  const [imageUrl, setImageUrl] = useState(formatProfilePicture(user.photoUrl, user.authProvider))

  const onDropAccepted = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setImageUrl(URL.createObjectURL(acceptedFiles[0]))
      setFile(acceptedFiles[0])
    }
  }, [])

  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    onDropAccepted
  })

  useEffect(() => {
    if (save) {
      const region = MX[2].filter(reg => reg[1] === userEdit.region)
      userEdit['region'] = region ? {
        'name': region[0][0],
        'code': region[0][1]
      } : null

      userEdit['birthday'] = birthday
      userEdit['photoUrl'] = file

      onSaveProfile(userEdit)
    }
  }, [birthday, file, onSaveProfile, save, userEdit])
  
  return (
    <Card sx={{ p: 1, pb: 0 }}>
      <CardContent sx={{ p: 0, '&:last-child': { pb: 0 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography sx={{ opacity: 0.8 }} variant="subtitle2" gutterBottom>
              Editar foto de perfil
            </Typography>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: '#373f470a',
                borderColor: '#373f471a',
                borderRadius: '2px',
                borderStyle: 'dashed',
                borderWidth: '2px',
                color: '#373f4799',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                mt: 1,
                outline: 'none',
                p: imageUrl !== '' ? 1 : 4,
                transition: 'border .24s ease-in-out'
              }}
              {...getRootProps({isDragActive, isDragAccept, isDragReject})}
            >
                <input {...getInputProps()} />
                {
                  // imageUrl !== '' ? (
                    <img
                      src={imageUrl}
                      alt="Event"
                      style={{ width: "50%" }}
                    />
                  // ) : (
                  //   <Typography variant="body1" align="center">
                  //     Arrastra una imagen o haz clic aquí
                  //   </Typography>
                  // )
                }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="firstName"
              InputLabelProps={{ shrink: true }}
              label="Nombre"
              onChange={handleChange('firstName')}
              value={userEdit.firstName}
              placeholder="Ingresa tu nombre"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="lastName"
              InputLabelProps={{ shrink: true }}
              label="Apellidos"
              onChange={handleChange('lastName')}
              value={userEdit.lastName}
              placeholder="Ingresa tus apellidos"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-nickname-input"
              InputLabelProps={{ shrink: true }}
              label="Apodo"
              onChange={handleChange('nickname')}
              value={userEdit.nickname}
              placeholder="Ingresa tu apodo"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel shrink id="outlined-gender-input">Género</InputLabel>
              <Select
                labelId="outlined-gender-input"
                id="outlined-gender-input"
                label="Género"
                value={userEdit.gender}
                onChange={handleChange('gender')}
                notched
              >
                <MenuItem value={'Femenino'}>Femenino</MenuItem>
                <MenuItem value={'Masculino'}>Masculino</MenuItem>
              </Select>
              <FormHelperText>Este campo no pretende definir cómo te identificas</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              id="outlined-email-input"
              InputLabelProps={{ shrink: true }}
              label="Email"
              type="email"
              value={user.email}
              placeholder="usuario@mail.com"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="phone">Teléfono</InputLabel>
              <InputMask
                mask="+52 999 999 9999"
                onChange={handleChange('phone')}
                value={userEdit.phone}
              >
                { (inputProps) => <OutlinedInput {...inputProps} id="phone" label="Teléfono" name="number" type="tel" /> }
              </InputMask>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={mxLocale}>
              <DatePicker
                disableFuture
                label="Fecha de Nacimiento"
                mask="__/__/____"
                openTo="year"
                views={['year', 'month', 'day']}
                value={birthday}
                onChange={(newValue) => {
                  setBirthday(newValue)
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-outlined-city">Estado de Residencia</InputLabel>
              <Select
                labelId="select-outlined-city"
                id="select-outlined-city"
                onChange={handleChange('region')}
                value={userEdit.region}
                label="Estado de Residencia"
              >
                <MenuItem value=''>
                  <em></em>
                </MenuItem>
                { 
                  data.filter(
                    (cntry) => cntry['countryShortCode'] === 'MX'
                  )[0].regions.map(city =>
                    <MenuItem key={city['name']} value={city['shortCode']}>
                      {city['name']}
                    </MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Edit