import React from 'react'

import {
  Container,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography
} from '@mui/material'


import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'

const Contact = (props) => {
  const { id } = props

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ my: 4, mb: 8, py: 0 }} alignItems='center'>
          <Grid item xs={12}>
            <Typography id={id} align='center' variant="h4" gutterBottom>
              <b>CONTACTO</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems="center" direction="row" justifyContent="center" spacing={2}>
              <IconButton aria-label="facebook" component={Link} href="https://www.facebook.com/fmfutboltenis" target="_blank">
                <FacebookIcon color="primary" fontSize="inherit" />
              </IconButton>
              <IconButton aria-label="delete" component={Link} href="https://www.instagram.com/fmfutboltenis/" target="_blank">
                <InstagramIcon color="primary" fontSize="instagram" />
              </IconButton>
              <IconButton aria-label="delete" component={Link} href="https://twitter.com/fmfutboltenis" target="_blank">
                <TwitterIcon color="primary" fontSize="twitter" />
              </IconButton>
              <IconButton aria-label="linkedin" component={Link} href="https://www.linkedin.com/company/federaci%C3%B3n-mexicana-de-f%C3%BAtbol-tenis/" target="_blank">
                <LinkedInIcon color="primary" fontSize="inherit" />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} align="center">
            <Typography variant="h5">
              CEO
            </Typography>
            <Typography variant="h6">
              <b>José Ignacio Paoli García - Díaz</b>
            </Typography>
            <Typography variant="h6">
              +52 55 1923 2431
            </Typography>
            <Typography variant="h6">
              jpaoli@fmfta.mx
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} align="center">
            <Typography variant="h5">
              COO
            </Typography>
            <Typography variant="h6">
              <b>Jose Amortegui</b>
            </Typography>
            <Typography variant="h6">
              +52 55 3383 8457
            </Typography>
            <Typography variant="h6">
              jamortegui@fmfta.mx
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography id={id} align='center' sx={{ mt: 2 }} variant="h5" gutterBottom>
              <b>Presencia Regional</b>
            </Typography>
            {/* <Typography sx={{ mt: 2 }} variant="h6" gutterBottom>
              Si quieres ser parte de nuestro proyecto, contáctanos en los siguientes números.
            </Typography> */}
          </Grid>
          {/* <Grid item xs={12} sx={{ mt: 2 }} align="center">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                  Norte
                </Typography>
                <Typography variant="h6" gutterBottom>
                  <b>Salvador Moreno</b>
                </Typography>
                <Typography variant="h6">
                  +52 22 8108 4670
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" gutterBottom>
                  Norte Occidente
                </Typography>
                <Typography variant="h6" gutterBottom>
                  <b>René Madero</b>
                </Typography>
                <Typography variant="h6">
                  +52 32 2135 6996
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12} align="center">
            <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md>
                <Typography variant="h6">
                  Norte
                </Typography>
                <Typography variant="body1">
                  <b>Salvador Moreno</b>
                </Typography>
                <Typography variant="body1">
                  +52 22 8108 4670
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md>
                <Typography variant="h6">
                  Norte Occidente
                </Typography>
                <Typography variant="body1">
                  <b>René Madero</b>
                </Typography>
                <Typography variant="body1">
                  +52 32 2135 6996
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} md>
                <Typography variant="h6">
                  Centro Norte
                </Typography>
                <Typography variant="body1">
                  <b>Guillermo León</b>
                </Typography>
                <Typography variant="body1">
                  +52 44 9586 0171
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4} md>
                <Typography variant="h6">
                  Centro
                </Typography>
                <Typography variant="body1">
                  <b>Diego Abraham Gutiérrez</b>
                </Typography>
                <Typography variant="body1">
                  +52 56 1125 9088
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} md>
                <Typography variant="h6">
                  Sur
                </Typography>
                <Typography variant="body1">
                  <b>Bruno Veraza</b>
                </Typography>
                <Typography variant="body1">
                  +52 55 6319 9200
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default Contact