import { createTheme } from '@mui/material/styles'
import red from '@mui/material/colors/red'
import { esES } from '@mui/material/locale'

// 222222 - Eerie Black
// 252627 - Raising Black 373f47

const theme = createTheme({
  root: {
    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
      outline: "none"
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        }
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'Batsand',
          src: `local('Batsand'), local('Batsand'), url('fonts/Batsand.ttf')`,
        },
        body: {
          // backgroundImage: 'url(fmfta/background.jpeg)',
          overflow: 'auto !important',
          padding: '0 !important',
        }
      }
    },
  },
  palette: {
    primary: {
      main: '#373f47',
    },
    secondary: {
      main: '#2b303a',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#f8f9fc' // '#f8f9fc',// '#f0f0f0',
    },
    text: {
      primary: '#535461',
      secondary: '#d3d3d7'
    }
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    // fontFamily: 'IBM Plex Sans, sans-serif',
    // fontFamily: 'Batsand, sans-serif',
  }
}, esES)

export default theme