import React, { useEffect, useCallback, useState } from 'react'

import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'

import mxLocale from 'date-fns/locale/es'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'
import TimePicker from '@mui/lab/TimePicker'

import GooglePlacesAutocomplete from 'components/google'

import { useDropzone } from 'react-dropzone'

import data from 'country-region-data/data.json'
import { MX } from 'country-region-data/dist/data.js'

const CreateEvent = (props) => {

  const { create, edit, onCreateEvent } = props

  const [newEvent, setNewEvent] = useState({
    'amount': edit ? edit.amount : '',
    'description': edit ? edit.description : '',
    'place': edit ? edit.place : '',
    'region': edit ? edit.region.code : '',
    'title': edit ? edit.title : '',
  })

  const [file, setFile] = useState('')

  const handleChange = (prop) => (event) => {
    setNewEvent({ ...newEvent, [prop]: event.target.value })
  }
  
  const [imageUrl, setImageUrl] = useState(edit ? edit.image : '')

  const onDropAccepted = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      setImageUrl(URL.createObjectURL(acceptedFiles[0]))
      setFile(acceptedFiles[0])
    }
  }, [])

  const {
    // acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    onDropAccepted
  })

  const [date, setDate] = useState(edit ? new Date(edit.date.seconds * 1000) : null)
  const [time, setTime] = useState(edit ? new Date(edit.time.seconds * 1000) : null)

  const [priority, setPriority] = useState(edit ? edit.priority : false)

  const handlePriorityChange = (event) => {
    setPriority(event.target.checked)
  }
  
  const [place, setPlace] = useState(edit ? edit.place : '')

  const onPlaceChanged = (place) => {
    setPlace(place.description)
  }

  useEffect(() => {
    if (create) {
      // if (edit) {
      //   newEvent['date'] = date
      //   newEvent['time'] = time
      //   newEvent['image'] = file
      //   newEvent['place'] = place
      //   newEvent['priority'] = priority
        
      //   console.log(newEvent)

      // } else {
        const region = MX[2].filter(reg => reg[1] === newEvent['region'])
        newEvent['region'] = newEvent['region'] ? {
          'name': region[0][0],
          'code': region[0][1]
        } : null
  
        newEvent['date'] = date
        newEvent['time'] = time
        newEvent['image'] = file
        newEvent['place'] = place
        newEvent['priority'] = priority
        console.log(newEvent)
        onCreateEvent(newEvent)
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [create])
  // }, [create, date, edit, file, newEvent, onCreateEvent, place, priority, time])

  return (
    <Card>
      <CardContent sx={{ p: 2, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="title"
              InputLabelProps={{ shrink: true }}
              label="Título"
              onChange={handleChange('title')}
              placeholder={ edit ? edit.title : 'Agrega un título' }
              value={newEvent.title}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="description"
              InputLabelProps={{ shrink: true }}
              label="Descripción"
              multiline
              onChange={handleChange('description')}
              placeholder={ edit ? edit.description : 'Agrega una descripción' }
              rows={4}
              value={newEvent.description}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="amount">Costo</InputLabel>
              <OutlinedInput
                id="amount"
                value={newEvent.amount}
                onChange={handleChange('amount')}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="Costo"
                placeholder={ edit ? edit.amount : null }
                type="number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={mxLocale}>
              <DatePicker
                disablePast
                label="Fecha"
                mask="__/__/____"
                openTo="year"
                views={['year', 'month', 'day']}
                value={date}
                onChange={(newValue) => {
                  setDate(newValue)
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={mxLocale}>
              <TimePicker
                label="Hora"
                value={time}
                onChange={(newValue) => {
                  setTime(newValue);
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel shrink id="select-outlined-city">Estado</InputLabel>
              <Select
                labelId="select-outlined-city"
                id="select-outlined-city"
                onChange={handleChange('region')}
                value={newEvent.region}
                label="Estado"
                notched
              >
                <MenuItem value=''>
                  <em></em>
                </MenuItem>
                { 
                  data.filter(
                    (cntry) => cntry['countryShortCode'] === 'MX'
                  )[0].regions.map(city =>
                    <MenuItem key={city['name']} value={city['shortCode']}>
                      {city['name']}
                    </MenuItem>
                  )
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <GooglePlacesAutocomplete label="Ubicación" onPlaceChanged={onPlaceChanged} placeholder={ edit ? edit.place : 'Busca una dirección' } />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ opacity: 0.8 }} variant="subtitle2" gutterBottom>
              Subir Imagen
            </Typography>
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: '#373f471a',
                borderColor: '#373f4744',
                borderRadius: '2px',
                borderStyle: 'dashed',
                borderWidth: '2px',
                color: '#373f4799',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                mt: 1,
                outline: 'none',
                p: imageUrl !== '' ? 1 : 4,
                transition: 'border .24s ease-in-out'
              }}
              {...getRootProps({isDragActive, isDragAccept, isDragReject})}
            >
                <input {...getInputProps()} />
                {
                  imageUrl !== '' ? (
                    <img
                      src={imageUrl}
                      alt="Event"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <Typography variant="body1" align="center">
                      Arrastra una imagen o haz clic aquí
                    </Typography>
                  )
                }
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={priority} onChange={handlePriorityChange} />} label="Evento preferente" />
            </FormGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default CreateEvent