import React, { useState } from 'react'

import { Outlet } from 'react-router-dom'

import useAuth from 'hooks/auth'

// import md5 from 'md5'

import {
  Avatar,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  Typography
} from '@mui/material'

import Edit from 'pages/dashboard/profile/edit'

import { MX } from 'country-region-data/dist/data.js'

const Information = () => {
  const { updateUserProfile, user } = useAuth()

  const [open, setOpen] = useState(false)
  const [save, setSave] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSave = () => {
    setSave(true)
    handleClose()
  }
  
  const onSaveProfile = (profile) => {
    setSave(false)
    updateUserProfile(profile)
  }

  const formatDate = (date) => {
    var options = { year: 'numeric', month: 'long', day: 'numeric' }
    return new Date(date).toLocaleDateString('es-MX', options)
  }

  const formatProfilePicture = (photoUrl, provider) => {
    if (provider === 'facebook') {
      return `${photoUrl}?type=large`
    } else if (provider === 'google') {
      return photoUrl.replace('s96-c', 's600-c')
    } else {
      // return 'https://www.gravatar.com/avatar/' + md5(user.email) + '?s=200'
      return 'https://firebasestorage.googleapis.com/v0/b/fmftamx.appspot.com/o/default%2Fratio_16_9.png?alt=media&token=dad6c3f8-6bf2-448e-82e4-90ac56e3ee8d'
    }
  }
  
  if (!user) {
    return <Outlet />
  }

  return (
    <React.Fragment>
      <Card sx={{ p: 2 }}>
        <CardContent sx={{ p: 2, '&:last-child': { paddingBottom: 2 } }}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="center">
              {
                user ? (
                  <Avatar
                    // component={CardActionArea}
                    alt="Profile Picture"
                    src={formatProfilePicture(user.photoUrl, user.authProvider)}
                    sx={{
                      // '&:hover': {
                      //   borderColor: '#373f4722',
                      //   cursor: 'pointer',
                      //   opacity: 0.8
                      // },
                      // borderColor: '#373f471a',
                      // borderStyle: 'dashed',
                      // borderWidth: '4px',
                      height: 160,
                      // p: 2,
                      width: 160
                    }}
                  />
                ) : (
                  <Skeleton variant="circular" width={128} height={128} />
                )
              }
              
            </Grid>
            <Grid item xs={12} align="center">
              <Typography variant="body1">
                { user ? user.nickname : <Skeleton sx={{ width: '40%' }} />}
              </Typography>
              <Typography variant="h5">
                { user ? user.name : <Skeleton sx={{ width: '60%' }} /> }
              </Typography>
              <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
                { user ? (user.birthday ? formatDate(user.birthday.seconds * 1000) : '') : <Skeleton sx={{ width: '50%' }} />}
              </Typography>
              <Typography variant="body2" gutterBottom>
                { user ? user.email : <Skeleton sx={{ width: '80%' }} />}
              </Typography>
              <Typography variant="body2" gutterBottom sx={{ mb: 2 }}>
                { user ? user.phone : <Skeleton sx={{ width: '50%' }} />}
              </Typography>
              <Typography variant="subtitle2">
                { user ? (user.region ? MX[2].filter(reg => reg[1] === user.region.code)[0][0] : '') : <Skeleton sx={{ width: '55%' }} />}
              </Typography>
            </Grid>
            <Grid item xs={12} align="center">
              <Button disableElevation variant="contained" color="primary" onClick={handleClickOpen}>
                Editar perfil
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Editar Perfil</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <Edit user={user} save={save} onSaveProfile={onSaveProfile}/>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 2 }}>
          <Button disableElevation onClick={handleClose}>Cancelar</Button>
          <Button color='primary' disableElevation variant='contained' onClick={onSave}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default Information