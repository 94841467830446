import React, { useEffect } from 'react'

import useAuth from 'hooks/auth'

import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material'

import Empty from 'components/empty'

import Achievement from './achievement'

const Achievements = (props) => {

  const { user, achievements, getWinnersByUserId } = useAuth()

  useEffect(() => {
    getWinnersByUserId()
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card>
      <CardContent sx={{ p: 2, '&:last-child': { paddingBottom: 2 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Mis logros</b>
            </Typography>
          </Grid>
          {
            achievements.length > 0 ? (
              achievements.map((achievement) => {
                return (
                  <Grid item xs={12} key={achievement.id}>
                    <Achievement
                      uid={user.uid}
                      value={achievement}
                    />
                  </Grid>
                )
              })
            ) : (
              <Grid item xs={12}>
                <Empty image="/fmfta/logros.png" message="No hay datos disponibles" />
              </Grid>
            )
          }
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Achievements