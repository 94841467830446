import React from 'react'

import {
  Card,
  CardActionArea,
  CardContent,
  Typography
} from '@mui/material'

const formatDate = (date) => {
  var options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Date(date).toLocaleDateString('es-MX', options)
}

const formatHour = (date) => {
  var options = { hour: 'numeric', minute: 'numeric' }
  return new Date(date).toLocaleTimeString('es-MX', options)
}

const ListEvent = (props) => {
  const { onEventSelected, value } = props

  return (
    <CardActionArea onClick={() => onEventSelected(value)}>
      <Card sx={{ p: 1 }}>
        <CardContent sx={{ '&:last-child': { pb: 0 }, p: 0 }}>
          <Typography variant="body2" gutterBottom>
            { value.title }
          </Typography>
          <Typography color="#aaaaaa" variant="subtitle2" gutterBottom>
            { formatDate(value.date.seconds * 1000) } • { formatHour(value.time.seconds * 1000) }
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  )
}

export default ListEvent