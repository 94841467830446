import React, { useState } from 'react'

import {
  useLocation,
  useNavigate
} from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography
} from '@mui/material'

import mxLocale from 'date-fns/locale/es'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'

import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

// Blue 6c91c2
// Brighter blue 228cdb
// Green a2c5ac

import InputMask from 'react-input-mask'

import allCountries from 'country-region-data/data.json'
import { MX } from 'country-region-data/dist/data.js'

const useQuery = () => {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const SignUp = () => {
  const { signInWithFacebook, signInWithGoogle, signUpWithEmailAndPassword } = useAuth()

  const navigate = useNavigate()
  const query = useQuery()
  const ref = query.get('ref')

  const [birthday, setBirthday] = useState(null)
  const [password, setPassword] = useState('')

  const [user, setUser] = useState({
    'firstName': '',
    'lastName': '',
    'email': '',
    'nickname': '',
    'gender': '',
    'phone': '',
    'region': '',
  })

  const handleChange = (prop) => (event) => {
    setUser({ ...user, [prop]: event.target.value })
  }

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const googleSignIn = async () => {
    await signInWithGoogle(ref)
    navigate('/dashboard')
  }

  const facebookSignIn = async () => {
    await signInWithFacebook(ref)
    navigate('/dashboard')
  }

  const signUp = async () => {
    const region = MX[2].filter(reg => reg[1] === user.region)
    user['region'] = region ? {
      'name': region[0][0],
      'code': region[0][1]
    } : null
    user['birthday'] = birthday
    await signUpWithEmailAndPassword(user, password, ref)
    navigate('/dashboard')
  }

  const isReadyToRegister = user.firstName !== '' && user.lastName !== '' && user.email !== '' && (password !== '' && password.length >= 6) && user.nickname !== '' && user.gender !== '' && user.phone !== '' && user.region !== '' && birthday

  return (
    <Grid container spacing={0}>
      {/* <Grid item xs={12} md={5} lg={6} sx={{ background: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23000000' fill-opacity='0.04' fill-rule='evenodd'/%3E%3C/svg%3E")`, backgroundColor: 'white', display: { xs: 'none', md: 'flex' }  }}> */}
      <Grid item xs={12} md={5} lg={6} sx={{ background: 'url(fmfta/background-signin.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', display: { xs: 'none', md: 'flex' }  }}>
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={12} align="middle">
            <Box sx={{ p: 0, width: '100%' }}>
              <img
                src="/images/fmft-logo.png"
                alt="FMFTA Logo"
                style={{ opacity: 0.9, verticalAlign: 'middle', width: '60%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} lg={6}>
        <Grid container spacing={0} alignItems="center" justify="center" sx={{ background: 'white', minHeight: '100vh', p: { xs: 0, md: 8 } }}>
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                      Regístrate
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      id="outlined-name-input"
                      InputLabelProps={{ shrink: true }}
                      label="Nombre"
                      value={user.firstName}
                      onChange={handleChange('firstName')}
                      placeholder="Ingresa tu nombre"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      id="outlined-lastname-input"
                      InputLabelProps={{ shrink: true }}
                      label="Apellidos"
                      value={user.lastName}
                      onChange={handleChange('lastName')}
                      placeholder="Ingresa tus apellidos"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-nickname-input"
                      InputLabelProps={{ shrink: true }}
                      label="Apodo"
                      value={user.nickname}
                      onChange={handleChange('nickname')}
                      placeholder="Ingresa tu apodo"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-email-input"
                      InputLabelProps={{ shrink: true }}
                      label="Email"
                      type="email"
                      value={user.email}
                      onChange={handleChange('email')}
                      placeholder="Ingresa tu email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <TextField
                      id="outlined-password-input"
                      InputLabelProps={{ shrink: true }}
                      label="Contraseña"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="6+ caracteres"
                      fullWidth
                    /> */}
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink htmlFor="outlined-password">Password</InputLabel>
                      <OutlinedInput
                        id="outlined-password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        // error={errorPassword}
                        // helperText={errorPasswordText}
                        placeholder="6+ caracteres"
                        notched
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TextField
                      id="outlined-confirm-password-input"
                      InputLabelProps={{ shrink: true }}
                      label="Confirmar contraseña"
                      type="password"
                      placeholder="Las contraseñas deben coincidir"
                      fullWidth
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel shrink id="outlined-gender-input">Género</InputLabel>
                      <Select
                        labelId="outlined-gender-input"
                        id="outlined-gender-input"
                        label="Género"
                        value={user.gender}
                        onChange={handleChange('gender')}
                        notched
                      >
                        <MenuItem value={'Femenino'}>Femenino</MenuItem>
                        <MenuItem value={'Masculino'}>Masculino</MenuItem>
                      </Select>
                      <FormHelperText>Este campo no pretende definir cómo te identificas</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink htmlFor="phone">Teléfono</InputLabel>
                      <InputMask
                        mask="+52 999 999 9999"
                        onChange={handleChange('phone')}
                        value={user.phone}
                      >
                        { (inputProps) => <OutlinedInput notched {...inputProps} id="phone" label="Teléfono" name="number" type="tel" /> }
                      </InputMask>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={mxLocale}>
                      <DatePicker
                        disableFuture
                        label="Fecha de Nacimiento"
                        openTo="year"
                        views={['year', 'month', 'day']}
                        value={birthday}
                        onChange={(newValue) => {
                          setBirthday(newValue)
                        }}
                        renderInput={(params) => <TextField fullWidth InputLabelProps={{ shrink: true }} {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink id="select-outlined-city">Estado de Residencia</InputLabel>
                      <Select
                        labelId="select-outlined-city"
                        id="select-outlined-city"
                        value={user.region}
                        onChange={handleChange('region')}
                        label="Estado de Residencia"
                        notched
                      >
                        <MenuItem value='any'>
                          <em></em>
                        </MenuItem>
                        { 
                          allCountries.filter(
                            (cntry) => cntry['countryShortCode'] === 'MX'
                          )[0].regions.map(city =>
                            <MenuItem value={city['shortCode']}>
                              {city['name']}
                            </MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Button disabled={!isReadyToRegister} disableElevation fullWidth size="large" variant="contained" onClick={signUp}>
                        Registrar
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      <Grid item xs={12} md={12} lg={6} align="center">
                        <Button
                          disableElevation
                          fullWidth
                          onClick={googleSignIn}
                          size="large"
                          startIcon={<GoogleIcon />}
                          sx={{
                            background: '#373f470a', 
                            color: '#444'
                          }}
                        >
                          Continuar con Google
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6} align="center">
                        <Button 
                          disableElevation
                          fullWidth
                          onClick={facebookSignIn}
                          size="large"
                          startIcon={<FacebookIcon />}
                          sx={{
                            '&:hover': {
                              backgroundColor: '#4267B2',
                            },
                            background: '#4267B2', 
                            color: 'white'
                          }}
                        >
                          Continuar con Facebook
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ pt: 2 }} variant="body1">
                      ¿Ya tienes una cuenta? <Link sx={{ fontWeight: 'bolder', textDecoration: 'none' }} href="/signin">Inicia sesión</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignUp