import React, { useState } from 'react'

import useAuth from 'hooks/auth'

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Snackbar,
  TextField,
  Typography
} from '@mui/material'

const Referral = () => {
  const [open, setOpen] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')

  const [email, setEmail] = useState('')

  const { sendInvitationEmail } = useAuth()

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  const sendEmail = async () => {
    setEmail('')

    const result = await sendInvitationEmail(email)
    setSuccess(result.success)

    if (result.success) {
      setMessage(result.message)
    } else {
      setMessage(result.error)
    }

    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <Card sx={{ p: 2 }}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} align="middle">
            <Box sx={{ pt: 2, width: '100%' }}>
              <img
                src="/fmfta/amigos.png"
                alt="Referral"
                style={{ filter: 'grayscale(0.4)', opacity: '0.9', verticalAlign: 'middle', width: '40%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom sx={{ mt: 2 }} align="center">
              ¡Invíta a tus amigos a formar parte de esta increíble experiencia!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-name-input"
              InputLabelProps={{ shrink: true }}
              label="Enviar invitación"
              placeholder="Ingresa el correo electrónico de tu amigo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={!validateEmail(email)} disableElevation fullWidth variant="contained" onClick={sendEmail}>
              Enviar
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={ success ? 'success' : 'error' } sx={{ width: '100%' }}>
          { message}
        </Alert>
      </Snackbar>
    </Card>
  )
}

export default Referral