import React, { useEffect, useState } from 'react'

import { Outlet } from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  Grid,
  Typography
} from '@mui/material'

import Data from './data'
import Regions from './regions'

const Home = () => {
  const { events, getEvents, getUsers, user, users } = useAuth()

  const [eventsByRegion, setEventsByRegion] = useState([])
  const [usersByRegion, setUsersByRegion] = useState([])

  useEffect(() => {
    getEvents()
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const regions = {}
    users.forEach(user => {
      const code = user.region ? user.region.code : 'NA'
      const name = user.region ? user.region.name : 'Sin Asignar'
      regions[code] = {
        ...regions[code],
        id: code,
        count: regions[code] ? regions[code].count + 1 : 1,
        name: name
      }
    })
    setUsersByRegion(Object.values(regions))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    const regions = {}
    events.forEach(event => {
      const code = event.region ? event.region.code : 'NA'
      const name = event.region ? event.region.name : 'Sin Asignar'
      regions[code] = {
        ...regions[code],
        id: code,
        count: regions[code] ? regions[code].count + 1 : 1,
        name: name
      }
    })
    setEventsByRegion(Object.values(regions))
  }, [events])

  if (!user) {
    return <Outlet />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h5">
          ¡Hola {user.name}!
        </Typography>
        <Typography variant="body2" color="#373f4755">
          Bienvenido a la página de administración de la FMFT
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Data title="Total de Usuarios" value={users ? users.length : 0} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Data title="Total de Eventos" value={events ? events.length : 0} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Regions regions={usersByRegion} title="Usuarios por región" />
      </Grid>
      <Grid item xs={12} md={6}>
        <Regions regions={eventsByRegion} title="Eventos por región" />
      </Grid>
    </Grid>
  )
}

export default Home