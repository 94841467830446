import React from 'react'

import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material'

const OurKits = (props) => {
  const { id } = props

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ my: 4, mb: 8, py: 0 }} alignItems='center'>
          <Grid item xs={12}>
            <Typography id={id} align='center' variant="h4" gutterBottom>
              <b>CONOCE NUESTROS KITS DE FÚTBOL TENIS</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Box sx={{ px: 4, width: '100%' }}>
              <img
                src='fmfta/kit.png'
                alt='Kit Fútbol Tenis'
                style={{ width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <b>Medidas:</b> 6.95 metros de largo / 3.75 metros de ancho / 1.20 metros de altura
            </Typography>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <b>Descripción:</b>
              <ul>
                <li>El kit para practicar Fútbol Tenis viene en una mochila portátil muy fácil de transportar.</li>
                <li>No se requiere mayor infraestructura más que un espacio plano donde se pueda montar la cancha.</li>
                <li>El diseño de nuestro kit permite el montaje y desmontaje del mismo en menos de 5 minutos.</li>
              </ul>
            </Typography>
            {/* <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <b>Adquiérelo en Amazon.</b>
            </Typography> */}
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <a href="https://www.amazon.com.mx/dp/B09C13VVFP" rel="noopener noreferrer" target="_blank">Adquiere tu kit oficial de la FMFT en Amazon</a>
            </Typography>
            <Typography sx={{ mx: 6, my: 4 }} variant="h6" gutterBottom>
              <a href="https://articulo.mercadolibre.com.mx/MLM-1894609013-cancha-de-futbol-tenis-oficial-federacion-mexicana-fmfta-_JM" rel="noopener noreferrer" target="_blank">Adquiere tu kit oficial de la FMFT en Mercado Libre</a>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

export default OurKits