import React from 'react'

import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material'

const Data = (props) => {

  const { title, value } = props

  return (
    <Card sx={{ p: 2 }}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="body1" component="p" gutterBottom>
              { title }
            </Typography>
            <Typography variant="h4" component="p">
              { value }
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Data