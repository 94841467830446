import React, { useState } from 'react'

import {
  useNavigate 
} from 'react-router-dom'

import useAuth from 'hooks/auth'

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material'

import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

// Blue 6c91c2
// Brighter blue 228cdb

import { UserAgent } from '@quentin-sommer/react-useragent'

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

const SignIn = (props) => {
  const navigate = useNavigate()
  const { signInWithEmail, signInWithFacebook, signInWithGoogle } = useAuth()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [errorEmail, setErrorEmail] = useState(false)
  const [errorEmailText, setErrorEmailText] = useState(errorEmail ? 'Email inválido' : '')
  
  const [errorPassword, setErrorPassword] = useState(false)
  const [errorPasswordText, setErrorPasswordText] = useState(errorPassword ? 'Password incorrecta' : '')
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const googleSignIn = async (parser) => {
    const analytics = {
      id: 'signInWithGoogle',
      metadata: parser.getResult()
    }

    await signInWithGoogle(undefined, analytics)
    navigate('/dashboard')
  }

  const facebookSignIn = async (parser) => {
    const analytics = {
      id: 'signInWithFacebook',
      metadata: parser.getResult()
    }

    await signInWithFacebook(undefined, analytics)
    navigate('/dashboard')
  }

  const signIn = async (parser) => {
    if (!validateEmail(email)) {
      setErrorEmail(true)
      setErrorEmailText('Email inválido')
      return
    }

    
    const analytics = {
      id: 'signInWithEmailAndPassword',
      metadata: parser.getResult()
    }

    const error = await signInWithEmail(email, password, analytics)
    if (error) {
      const errorCode = error.code
      if (errorCode === 'auth/invalid-email') {
        setErrorEmailText('Email inválido')
        setErrorEmail(true)
      } else if (errorCode === 'auth/user-disabled') {
        setErrorEmailText('Usuario deshabilitado')
        setErrorEmail(true)
      } else if (errorCode === 'auth/user-not-found') {
        setErrorEmailText('Usuario no encontrado')
        setErrorEmail(true)
      } else if (errorCode === 'auth/wrong-password') {
        setErrorPasswordText('Password incorrecta')
        setErrorPassword(true)
      } else {
        console.log(error)
        setErrorEmailText('Error desconocido')
        setErrorPasswordText('Error desconocido')
      }
      return
    }

    navigate('/dashboard')
  }

  const handleEmailChanged = (event) => {
    setErrorEmail(false)
    setErrorEmailText('')
    setEmail(event.target.value)
  }

  const handlePasswordChanged = (event) => {
    setErrorPassword(false)
    setErrorPasswordText('')
    setPassword(event.target.value)
  }

  return (
    <Grid container spacing={0}>
      {/* <Grid item xs={12} md={5} lg={6} sx={{ background: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23000000' fill-opacity='0.04' fill-rule='evenodd'/%3E%3C/svg%3E")`, backgroundColor: 'white', display: { xs: 'none', md: 'flex' }  }}> */}
      <Grid item xs={12} md={5} lg={6} sx={{ background: 'url(fmfta/background-signin.png)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', display: { xs: 'none', md: 'flex' }  }}>
        <Grid container spacing={0} alignItems="center" justify="center">
          <Grid item xs={12} align="middle">
            <Box sx={{ p: 0, width: '100%' }}>
              <img
                src="/images/fmft-logo.png"
                alt="FMFTA Logo"
                style={{ opacity: 0.9, verticalAlign: 'middle', width: '60%' }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7} lg={6}>
        <Grid container spacing={0} alignItems="center" justify="center" sx={{ background: 'white', minHeight: '100vh', p: { xs: 0, md: 4, lg: 8 } }}>
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                      Inicia sesión
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={errorEmail}
                      helperText={errorEmailText}
                      id="outlined-email-input"
                      InputLabelProps={{ shrink: true }}
                      label="Email"
                      value={email}
                      onChange={handleEmailChanged}
                      placeholder="Ingresa tu email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <TextField
                      error={errorPassword}
                      helperText={errorPasswordText}
                      id="outlined-password-input"
                      InputLabelProps={{ shrink: true }}
                      label="Contraseña"
                      type="password"
                      value={password}
                      onChange={handlePasswordChanged}
                      placeholder="6+ caracteres"
                      fullWidth
                    /> */}
                    <FormControl fullWidth variant="outlined">
                      <InputLabel shrink htmlFor="outlined-password">Password</InputLabel>
                      <OutlinedInput
                        id="outlined-password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={handlePasswordChanged}
                        error={errorPassword}
                        helperText={errorPasswordText}
                        placeholder="6+ caracteres"
                        notched
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      <Link sx={{ fontWeight: 'bolder', textDecoration: 'none' }} href="/forgot">¿Olvidaste tu contraseña?</Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="flex-end">
                      <UserAgent returnFullParser>
                        {
                          parser => (
                            <Button variant="contained" disableElevation size="large" onClick={() => signIn(parser)}>
                              Iniciar sesión
                            </Button>
                          )
                        }
                      </UserAgent>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ pt: 2 }}>
                      <Grid item xs={12} md={12} lg={6} align="center">
                        <UserAgent returnFullParser>
                          {
                            parser => (
                              <Button
                                disableElevation
                                fullWidth
                                onClick={() => googleSignIn(parser)}
                                size="large"
                                startIcon={<GoogleIcon />}
                                sx={{
                                  background: '#373f470a', 
                                  color: '#444'
                                }}
                              >
                                Continuar con Google
                              </Button>
                            )
                          }
                        </UserAgent>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6} align="center">
                        
                      <UserAgent returnFullParser>
                          {
                            parser => (
                              <Button 
                                disableElevation
                                fullWidth
                                onClick={() => facebookSignIn(parser)}
                                size="large"
                                startIcon={<FacebookIcon />}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: '#4267B2',
                                  },
                                  background: '#4267B2', 
                                  color: 'white'
                                }}
                              >
                                Continuar con Facebook
                              </Button>
                            )
                          }
                        </UserAgent>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ pt: 4 }} variant="body1">
                      ¿No tienes una cuenta? <Link sx={{ fontWeight: 'bolder', textDecoration: 'none' }} href="/signup">Regístrate</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SignIn