import * as React from 'react'

import { 
  BrowserRouter, 
  Route,
  Routes
} from 'react-router-dom'

import ProtectedRoute from 'routes/protected'

import Admin from 'pages/admin'
import Dashboard from 'pages/dashboard'
import Landing from 'pages/landing'
import Forgot from 'pages/registration/forgot'
import SignIn from 'pages/registration/singin'
import SignUp from 'pages/registration/signup'
import Verify from 'pages/registration/verify'

const App = (props) => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="admin/*" 
          element={
            <ProtectedRoute redirectTo="/signin" role="admin">
              <Admin />
            </ProtectedRoute>
          } 
        />
        <Route path="dashboard/*" 
          element={
            <ProtectedRoute redirectTo="/signin" role="user">
              <Dashboard />
            </ProtectedRoute>
          } 
        />
        <Route path="forgot" element={<Forgot />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="verify" element={<Verify />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App