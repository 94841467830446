import React, { useEffect, useState } from 'react'

import useAuth from 'hooks/auth'

import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  Typography
} from '@mui/material'

import Empty from 'components/empty'
import Summary from 'components/events/summary'
import Event from 'pages/shared/events/event'

const MyEvents = () => {

  const { getMyEvents, myEvents, user } = useAuth()

  const [events, setEvents] = useState([])

  useEffect(() => {
    getMyEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const filtered = myEvents.filter((event) => {
      const time = (new Date(event.date.seconds * 1000)).getTime()
      return time >= Date.now()
    })
    .sort((a, b) => a.date.seconds - b.date.seconds)

    setEvents(filtered)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myEvents])

  const [openSummary, setOpenSummary] = useState(false)
  const handleCloseSummary = () => {
    setOpenSummary(false)
  }

  const [selectedEvent, setSelectedEvent] = useState(null)

  const onEventSelected = (event) => {
    setSelectedEvent(event)
    setOpenSummary(true)
  }

  return (
    <Card sx={{ p: 2 }}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              <b>Mis próximos eventos</b>
            </Typography>
          </Grid>
          {
            events.length > 0 ? (
              events.map((item, index) => (
                <Grid key={`${item.eventId}-${index}`} item xs={12}>
                  <Event value={item} onEventSelected={onEventSelected}/>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                <Empty image="/fmfta/eventos.png" message="No hay eventos disponibles" />
              </Grid>
            )
          }
        </Grid>
      </CardContent>
      <Dialog open={openSummary} onClose={handleCloseSummary}>
        <DialogContent sx={{ p: 0 }}>
          <Summary user={user} value={selectedEvent}/>
        </DialogContent>
      </Dialog>
    </Card>
  )
}

export default MyEvents