import React from 'react'
import ReactDOM from 'react-dom'

import reportWebVitals from './reportWebVitals'

import { ThemeProvider } from '@mui/material/styles'
import theme from 'config/theme'

import {
  CssBaseline
} from '@mui/material'

import { AuthProvider } from 'context/provider/auth'
import { UserAgentProvider } from '@quentin-sommer/react-useragent'

import App from './app'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <CssBaseline />
      <AuthProvider>
        <UserAgentProvider ua={window.navigator.userAgent}>
          <App />
        </UserAgentProvider>
      </AuthProvider>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
