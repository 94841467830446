import React from 'react'

import {
  Box,
  Grid,
  Typography
} from '@mui/material'

const formatDate = (date) => {
  var options = { year: 'numeric', month: 'long', day: 'numeric' }
  return new Date(date).toLocaleDateString('es-MX', options)
}

const Achievement = (props) => {

  const { uid, value } = props

  const images = [
    '/images/gold-cup.png',
    '/images/gold-cup.png',
    '/images/silver-cup.png',
    '/images/silver-cup.png',
    '/images/bronze-cup.png',
    '/images/bronze-cup.png'
  ]

  const getImageFromResult = () => {
    const winners = value.winners
    const index = winners.findIndex(winner => winner.id === uid)
    return images[index]
  }

  return (
    <Grid container spacing={0} alignItems='center' sx={{ p: 1 }}>
      <Grid item xs={3} align="middle">
        <Box sx={{ p: 0, width: '100%' }}>
          <img
            src={getImageFromResult()}
            alt="Winner Cup"
            style={{ opacity: 0.9, verticalAlign: 'middle', width: '50%' }}
          />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={0} alignItems='center'>
          <Grid item xs={12}>
            <Typography variant="body2">
              { value.name }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" sx={{ opacity: 0.6 }}>
              { formatDate(value.date.seconds * 1000) }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Achievement