import React from 'react'

import {
  Grid,
} from '@mui/material'

import Achievements from './achievements'
import Information from './information'
import MyEvents from './myevents'

const Profile = () => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={3}>
        <Information />
      </Grid>
      <Grid item xs={12} md={6} lg={5} order={{ xs: 2, md: 1, lg: 1}}>
        <MyEvents />
      </Grid>
      <Grid item xs={12} md={6} lg={4} order={{ xs: 1, md: 2, lg: 2}}>
        <Achievements />
      </Grid>
    </Grid>
  )
}

export default Profile