import React from 'react'

import {
  Box,
  Grid,
  Typography
} from '@mui/material'

const Winner = (props) => {

  const { index, winner } = props

  // Parece que no tienes eventos creados 

  const images = [
    '/images/gold-cup.png',
    '/images/gold-cup.png',
    '/images/silver-cup.png',
    '/images/silver-cup.png',
    '/images/bronze-cup.png',
    '/images/bronze-cup.png'
  ]

  return (
    <Grid container spacing={0} alignItems='center'>
      <Grid item xs={3} align="middle">
        <Box sx={{ p: 0, width: '100%' }}>
          <img
            src={images[index]}
            alt="Winner Cup"
            style={{ opacity: 0.9, verticalAlign: 'middle', width: '50%' }}
          />
        </Box>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={0} alignItems='center'>
          <Grid item xs={12}>
            <Typography variant="body2">
              {winner.name}
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="caption" sx={{ opacity: 0.6 }}>
              { winner.email }
            </Typography>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Winner