import React from 'react'

import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@mui/material'

import { DataGrid, esES } from '@mui/x-data-grid'

const Regions = (props) => {

  const { regions, title } = props

  const columns = [
    {
      field: 'name',
      headerName: 'Región',
      width: 200,
    },
    {
      field: 'count',
      headerName: '# Usuarios',
      // width: 200,
    },
  ]

  console.log(regions)

  return (
    <Card sx={{ p: 2 }}>
      <CardContent sx={{ p: 0, '&:last-child': { paddingBottom: 0 } }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              { title }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              // disableColumnMenu
              columns={columns}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              pageSize={5}
              rows={regions}
              rowsPerPageOptions={[5]}
              sx={{ borderWidth: 0 }}
            />
          </Grid>
          {/* {
            Object.values(regions).sort((a, b) => b.count - a.count).map((region, index) => (
              <Grid key={`${index}`} item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="subtitle2">
                      {region.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} align="right">
                    <Typography variant="subtitle2">
                      {region.count}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))
          } */}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Regions