import React, { useEffect, useState } from 'react'

import useAuth from 'hooks/auth'

import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography
} from '@mui/material'

const Information = (props) => {

  const { eventId } = props

  const { 
    confirmParticipant, 
    getOrders,
    getParticipantsById,
    orders, 
    participants 
  } = useAuth()

  const [summary, setSummary] = useState({
    'interested': 0,
    'subscribed': 0,
    'remaining': 0
  })

  useEffect(() => {
    getParticipantsById()
    getOrders(eventId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    console.log('orders', orders)

    const subscribed = orders.filter(order => order.status !== 'Creada')
    const notsubscribed = orders.filter(order => order.status === 'Creada')
    const interested = []
    const remaining = []
    notsubscribed.forEach(order => {
      if (!interested.includes(order.uid)) {
        interested.push(order.uid)
        remaining.push(order.uid)
      }
    })

    subscribed.forEach(order => {
      const index = remaining.indexOf(order.uid)
      if (index > -1) {
        remaining.splice(index, 1)
      }
    })

    setSummary({
      'interested': interested.length,
      'subscribed': subscribed.length,
      'remaining': remaining.length
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders])

  const [selectedParticipant, setSelectedParticipant] = useState(null)

  const formattedParticipants = participants.map((participant) => (
    {
      'email': participant.email,
      'id': participant.uid,
      'label': `${participant.name} <${participant.email}>`,
      'name': participant.name,
    }
  ))

  const [status, setStatus] = useState('')

  useEffect(() => {
    if (selectedParticipant) {
      confirmParticipant(selectedParticipant.id)
      const p = participants.filter((p) => p.uid === selectedParticipant.id)[0]
      setStatus(p.status)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants, selectedParticipant])

  const onConfirm = () => {
    const p = participants.filter((p) => p.uid === selectedParticipant.id)[0]
    confirmParticipant(p)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="#373f47" variant="body1">
          Resumen del evento
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography color="#373f47" variant="body2">
              Interesados en el evento:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="#373f47aa" variant="body2">
              <b>{summary.interested}</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography color="#373f47" variant="body2">
              Participantes inscritos:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="#373f47aa" variant="body2">
              <b>{participants.length}</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography color="#373f47" variant="body2">
              Asistencia total:
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography color="#373f47aa" variant="body2">
              <b>{participants.filter(item => item.status === 'Confirmado').length}</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography color="#373f47" sx={{ pt: 2 }} variant="body1">
          Check-in
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          fullWidth
          options={formattedParticipants}
          renderInput={(params) => <TextField {...params} label="Buscar" />}
          value={selectedParticipant}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, newValue) => {
            setSelectedParticipant(newValue)
          }}
          size="small"
        />
      </Grid>
      <Grid item xs={12} sx={{ display: selectedParticipant ? 'block' : 'none' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography color="#373f47dd" variant="body2">{selectedParticipant ? selectedParticipant.name : ''}</Typography>
            <Typography color="#373f4799" variant="caption">{selectedParticipant ? selectedParticipant.email : ''}</Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Button disabled={status === 'Confirmado'} variant="contained" color="primary" size="small" onClick={onConfirm}>
              { status === 'Confirmado' ? 'Confirmado' : 'Confirmar' }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Information