import React, { useEffect, useMemo, useState } from 'react'

import { useLocation } from 'react-router-dom'

import {
  Outlet,
  // useParams
} from 'react-router-dom'

import useAuth from 'hooks/auth'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Pagination,
  SwipeableDrawer,
  Snackbar,
  Typography,
} from '@mui/material'

import FilterListIcon from '@mui/icons-material/FilterList'

import CreateEvent from './create'
import Event from './event'
import Filters from './filters'

import Summary from 'components/events/summary'
import Empty from 'components/empty'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

const Events = (props) => {
  const { window } = props

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  // const { id } = useParams()

  const { createEvent, events, getEvents, user } = useAuth()

  const [selectedEvent, setSelectedEvent] = useState()
  
  const [filteredEvents, setFilteredEvents] = useState(events)
  
  const [region, setRegion] = useState('Any')
  const onRegionChange = (value) => setRegion(value)
  
  const [range, setRange] = useState([null, null])
  const onRangeChange = (value) => setRange(value)

  const [open, setOpen] = useState(false)
  const [create, setCreate] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  
  const [openSummary, setOpenSummary] = useState(false)
  const handleCloseSummary = () => {
    setOpenSummary(false)
  }

  const onCreate = () => {
    setCreate(true)
    handleClose()
  }

  const onCreateEvent = (event) => {
    createEvent(event)
    setCreate(false)
  }

  useEffect(() => {
    getEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (events) {
      const filtered = events
      .filter(
        (event) => event.region.code === region || region === 'Any'
      )
      .filter((event) => {
        if (range[0] && range[1]) {
          const time = (new Date(event.date.seconds * 1000)).getTime()
          return time >= range[0].getTime() && time <= range[1].getTime()
        }
        return event
      })
      .filter((event) => {
        const time = (new Date(event.date.seconds * 1000)).getTime()
        return time >= Date.now()
      })
      .sort((a, b) => a.date.seconds - b.date.seconds)

      setFilteredEvents(filtered)

      setSelectedEvent(null)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [events, range, region])

  const [page, setPage] = useState(1)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const drawerBleeding = 56

  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen)
  }
  
  const container = window !== undefined ? () => window().document.body : undefined

  const onEventSelected = (newEvent) => {
    setSelectedEvent(newEvent)
    if (matches) {
      setOpenSummary(true)
    }
  }

  const query = useQuery()
  const result = query.get('result')
  const success = result === 'success'
  const message = result === 'success' ? 'Su pago fue exitoso, en breve se generará su registro.' : 'Su pago no fue exitoso, intente nuevamente.'

  const [openAlert, setOpenAlert] = useState(result !== null)
  const handleCloseAlert = () => {
    setOpenAlert(false)
  }

  if (!user) {
    return <Outlet />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6} md={6} lg={8}>
            <Typography variant="h5">
              Eventos
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={4} align="right" sx={{ display: user.role === 'admin' ? 'block' : 'none' }}>
            <Button disableElevation onClick={handleClickOpen} variant="contained">Crear Evento</Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} align="right" sx={{ display: { xs: 'block', md: 'block', lg: 'none' } }}>
        <IconButton onClick={toggleDrawer(true)}>
          <FilterListIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} lg={3} sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}>
        <Filters onRangeChange={onRangeChange} onRegionChange={onRegionChange} />
      </Grid>
      <Grid item xs={12} md={6} lg={ 5 }>
        {
          filteredEvents.length > 0 ? (
            <Grid container spacing={2}>
              {
                filteredEvents.slice((page - 1) * 3, ((page - 1) * 3) + 3).map((event) => (
                  <Grid key={event.id} item xs={12} md={12} lg={12}>
                    <Event onEventSelected={onEventSelected} value={event} />
                  </Grid>
                ))
              }
              <Grid item xs={12}>
                <Pagination count={filteredEvents ? Math.ceil(filteredEvents.length / 3) : 0}  onChange={handleChangePage} page={page} shape="rounded" sx={{ '& > *': { justifyContent: 'center', display: 'flex' } }}/>
              </Grid>
            </Grid>
          ) : (
            <Empty image="/fmfta/cercanos.png" message="No hay eventos disponibles" />
          )
        }
        {/* <Grid container spacing={2}>
          {
            !filteredEvents ? null : (
              filteredEvents.sort((a, b) => b.date.seconds - a.date.seconds).slice((page - 1) * 3, ((page - 1) * 3) + 3).map((event) => (
                <Grid key={event.id} item xs={12} md={12} lg={12}>
                  <Event onEventSelected={onEventSelected} value={event} />
                </Grid>
              ))
            )
          }
          <Grid item xs={12}>
            <Pagination count={filteredEvents ? Math.ceil(filteredEvents.length / 3) : 0}  onChange={handleChangePage} page={page} shape="rounded" sx={{ '& > *': { justifyContent: 'center', display: 'flex' } }}/>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid item xs={12} md={6} lg={4} sx={{ display: { xs: 'none', md: 'block' } }}>
        {
          selectedEvent ? (
            <Summary user={user} value={selectedEvent}/>
          ) : (
            <Card sx={{ p: 2 }}>
              <CardContent sx={{ '&:last-child': { pb: 0 }, p: 0 }}>
                <Empty image="/fmfta/seleccion.png" message="No has seleccionado un evento" />
              </CardContent>
            </Card>
          )
        }
      </Grid>
      <Snackbar open={openAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={10000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={ success ? 'success' : 'error' } sx={{ width: '100%' }}>
          { message }
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Crear Evento</DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <CreateEvent create={create} onCreateEvent={onCreateEvent} />
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button disableElevation onClick={handleClose}>Cancelar</Button>
          <Button color='primary' disableElevation variant='contained' onClick={onCreate}>Crear</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openSummary} onClose={handleCloseSummary}>
        <DialogContent sx={{ p: 0 }}>
          <Summary user={user} value={selectedEvent}/>
        </DialogContent>
      </Dialog>
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen
        ModalProps={{
          keepMounted: true,
        }}
      >
        {/* <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        > */}
          {/* <Skeleton variant="rectangular" height="100%" /> */}
          <Filters height="100%" onRangeChange={onRangeChange} onRegionChange={onRegionChange} />
        {/* </StyledBox> */}
      </SwipeableDrawer>
    </Grid>
  )
}

export default Events